import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import JsBarcode from 'jsbarcode';
import { saveAs } from 'file-saver';
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { apiList, invokeApi, invokeFormDataApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";

const GetProductsByName = () => {
  const { name } = useParams();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [itemName, setitemName] = useState("");
  const [catagory, setcatagory] = useState("");
  const [subCatagory, setsubCatagory] = useState("");
  const [type, settype] = useState("");
  const [serialNumber, setserialNumber] = useState("");
  const [positionNumber, setpositionNumber] = useState("");
  const [scannerType, setScannerType] = useState();
  const [items, setItems] = useState([""]);
  const [itemId, setitemId] = useState();
  const [productUpdated, setproductUpdated] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [categories, setcategories] = useState([]);
  const [imagePath, setImagePath] = useState();
  const [uniqueCode, setUniqueCode] = useState();
  const barcodeRef = useRef(null);
  const [barcodeVisibility, setbarcodeVisibility] = useState(false);

  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  
  useEffect(() => {
    const getProductByName = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        setLoading(true);
        let params = { itemName: name };

        let response = await invokeApi(
          config.apiDomains + apiList.getProducts,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          setTableData(response.data.itemData);
        } else if (response.status >= 400 && response.status < 510) {
          // alert(
          //   `Products with name of ${name} are not available, please go one step back and search for other PRODUCT NAME`
          // );
          setLoading(false);
        } else {
          // alert(
          //   "Something went wrong while saving property data. Please try again later!!"
          // );
          setLoading(false);
        }
        setLoading(false);
      } else navigate("/");
    };
    getProductByName();
  }, [name, cookies, productUpdated, navigate]);

  useEffect(() => {
    const getCategories = async (cat, subCat) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        // setLoading(true);
        let params = { category: cat, subCategory: subCat };

        let response = await invokeApi(
          config.apiDomains + apiList.getCategories,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          setcategories(response.data.categoryData);
        } else {
          // alert(
          //   "Something went wrong while saving property data. Please try again later!!"
          // );
        }
        // setLoading(false);
        // setActiveStep(0);
      } else navigate("/");
    };
    getCategories();
  }, [cookies, navigate]);

  const getProductById = async (itemId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { itemId };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.getProduct,
        params,
        cookies,
        "post"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);

        setitemName(response.data.itemData.itemName);
        setcatagory(response.data.itemData.category);
        setsubCatagory(response.data.itemData.subCategory);
        settype(response.data.itemData.type);
        setItems(response.data.itemData.itemDetails);
        setitemId(response.data.itemData.itemId);
        setserialNumber(response.data.itemData.serialNumber);
        setpositionNumber(response.data.itemData.positionNumber);
        setScannerType(response.data.itemData.scannerType);
        setUniqueCode(response.data.itemData.uniqueCode);
        setImagePath(response.data.itemData.scannerImagePath);
        setOpen(true);
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const deleteProduct = async (itemId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { itemId };

      let response = await invokeApi(
        config.apiDomains + apiList.deleteProduct,
        params,
        cookies,
        "post"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        const updatedTableData = tableData.filter(
          (row) => row.itemId !== itemId
        );

        // Update the state to re-render the component without the deleted row
        setTableData(updatedTableData);
        setsnackOpen(true);
        setsnackMessage("Product deleted successfully");
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const handleInputChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  const handleAddMore = () => {
    setItems([...items, ""]);
  };

  const handleDelete = (index) => {
    if (index > 0) {
      const newItems = [...items];
      newItems.splice(index, 1);
      setItems(newItems);
    } else {
      return;
    }
  };

  const updateProduct = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      setproductUpdated(false);
      let params = {
        itemName,
        itemId,
        serialNumber,
        category: catagory,
        subCategory: subCatagory,
        type,
        scannerType: scannerType,
        itemDetails: items,
        positionNumber: positionNumber,
        scannerImagePath: imagePath,
      };

      let response = await invokeApi(
        config.apiDomains + apiList.updateProduct,
        params,
        cookies,
        "post"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setproductUpdated(true);
        setitemName("");
        setcatagory("");
        setsubCatagory("");
        settype("");
        setserialNumber("");
        setItems([]);
        setitemId("");
        setsnackMessage("Product updated successfully");
        setsnackOpen(true);
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const handleOpenDialog = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenDialog(true);
  };

  // const handleDownload = async (imageUrl) => {
  //   try {
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "downloaded_image.png";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     // Revoke the blob URL to free up resources
  //     window.URL.revokeObjectURL(url);
  //     setOpenDialog(false);
  //   } catch (error) {
  //     // alert("Error downloading image:", error);
  //   }
  // };

  const handleDownload = () => {
    setOpenDialog(false);
  };

  // const generateQrCode = () => {
  //   const canvas = document.getElementById("qrcode");
  //   if (canvas) {
  //     const dataUrl = canvas.toDataURL("image/png");
  //     const blob = new Blob([dataUrl], { type: "image/png" });
  //     const qrCodeImageFile = new File([blob], `qr-${uniqueCode}.png`);
  //     uploadImageGetPath(qrCodeImageFile, "QR");
  //   }
  // };


  const uploadImageGetPath = async (file, codeType) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      try {
        let formData = new FormData();
        formData.append("scannerImage", file);
  
        let response = await invokeFormDataApi(
          config.apiDomains + apiList.uploadFile,
          formData,
          cookies
        );
  
        if (response.status >= 200 && response.status < 300) {
          setImagePath(response.data.imagePath);
        } else {
          console.error("Error while uploading image. HTTP status:", response.status);
        }
      } catch (error) {
        console.error("Error while uploading image:", error);
      }
    } else navigate("/");
  };

  const generateQrCodeImage = async () => {
    try{
      const canvas = document.getElementById("qrcode");
      const dataUrl = canvas.toDataURL("image/png");
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const qrCodeImageFile = await new File([blob], `qr-${uniqueCode}.png`);

      // Call the upload function here after QR code image generation is complete
      uploadImageGetPath(qrCodeImageFile, "QR");
    }catch (error) {
      console.error("Error while generating QR code image:", error);
    }
  };

  const generateBarcodeImage = async () => {
    try {
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, uniqueCode, { format: "CODE128" });
      const dataUrl = canvas.toDataURL("image/png");
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const barCodeImageFile = new File([blob], `bar-${uniqueCode}.png`);
  
      await uploadImageGetPath(barCodeImageFile, "Barcode");
    } catch (error) {
      console.error("Error while generating barcode image:", error);
    }
  };

  const handleScannerTypeChange = (event) => {
    const newType = event.target.value;
    const confirmChange = window.confirm(
      `Are you sure you want to change to ${newType}? This will generate a new ${newType}.`
    );
   
    if (confirmChange) {
      setScannerType(newType);
      if (newType === "QR Code") {
        generateQrCodeImage();
      } else if (newType === "Bar Code") {
        generateBarcodeImage();
      }
    }else{
      return;
    }
  };

  return (
    <Box sx={{ mt: { md: 4, xs: 1, minHeight: "95vh" } }}>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "Product deleted successfully"
                  ? "red"
                  : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              sx={{ minWidth: { md: 260, xs: 180 } }}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <a href={selectedImage} download="qr-code.png" target="_blank">
              <Button
                sx={{
                  borderRadius: "10px",
                  background: "red",
                  color: "black",
                  height: "32px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  minWidth: "150px",
                  "&:hover": {
                    background: "red",
                    color: "white",
                  },
                  mt: 5,
                }}
              >
                Download Image
              </Button>
            </a>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 700,
            minWidth: { md: 700, xs: 250 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
                mb: 3,
              }}
            >
              Update Product Details
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              <ClearIcon
                onClick={() => setOpen(false)}
                sx={{
                  cursor: "pointer",
                  mt: 0.8,
                  "&:hover": { backgroundColor: "#8A8A8A63" },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              spacing={2}
            >
              <Grid item md={6} xs={12}>
                <input
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    letterSpacing: 1,
                    paddingTop: "8px",
                    paddingBottom: "10px",
                    outline: "none",
                    minWidth: "91.5%",
                  }}
                  onChange={(event) => setitemName(event.target.value)}
                  value={itemName}
                  placeholder="Item Name"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <select
                  id="cars"
                  name="cars"
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    minHeight: "33.5px",
                    width: "95%",
                    paddingTop: "7px",
                    letterSpacing: 1,
                  }}
                  onChange={(event) => setcatagory(event.target.value)}
                  value={catagory}
                >
                  <option value="volvo" style={{ color: "red" }}>
                    Catagory
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat.category}>
                      {cat.category}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item md={6} xs={12}>
                
                <select
                  id="cars"
                  name="cars"
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    minHeight: "33.5px",
                    paddingTop: "7px",
                    paddingLeft: "10px",
                    minWidth: "95%",
                    letterSpacing: 1,
                  }}
                  onChange={(event) => setsubCatagory(event.target.value)}
                  value={subCatagory}
                >
                  <option value="volvo" style={{ color: "red" }}>
                    Subcategory
                  </option>
                  {(
                    categories.find((cat) => cat.category === catagory)
                      ?.subcategory || ""
                  )
                    .split(",")
                    .map((sub, subIndex) => (
                      <option key={subIndex} value={sub}>
                        {sub}
                      </option>
                    ))}
                </select>
              </Grid>
              <Grid item md={6} xs={12}>
                <select
                  id="cars"
                  name="cars"
                  style={{
                    border: "none",
                    // borderRadius: "10px"  ,
                    background: "#8A8A8A63",
                    minHeight: "33.5px",
                    paddingTop: "7px",
                    paddingLeft: "10px",
                    minWidth: "95%",
                    letterSpacing: 1,
                  }}
                  onChange={(event) => settype(event.target.value)}
                  value={type}
                >
                  <option value="Type" style={{ color: "red" }}>
                    Type
                  </option>
                  <option value="Single">Single</option>
                  <option value="Multiple">Multiple</option>
                </select>
              </Grid>
              <Grid item md={6} xs={12}>
                <input
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    letterSpacing: 1,
                    paddingTop: "8px",
                    paddingBottom: "10px",
                    outline: "none",
                    minWidth: "91.5%",
                  }}
                  onChange={(event) => setserialNumber(event.target.value)}
                  value={serialNumber}
                  placeholder="Item Name"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <input
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    letterSpacing: 1,
                    paddingTop: "8px",
                    paddingBottom: "10px",
                    outline: "none",
                    minWidth: "91.5%",
                  }}
                  onChange={(event) => setpositionNumber(event.target.value)}
                  value={positionNumber}
                  placeholder="Position Number"
                />
              </Grid>
              <Grid item md={12} xs={12} mb={{ xs: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      color: "#000000bf",
                      letterSpacing: 1,
                      fontSize: "17px",
                    }}
                  >
                    Type of Scanner:
                  </Box>
                  <Box>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        ".css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                          {
                            color: "black",
                          },
                        "@media (max-width: 600px)": {
                          flexDirection: "column",
                        },
                        gap: { md: 2, xs: 0 },
                      }}
                      onChange={handleScannerTypeChange}
                      value={scannerType}
                    >
                      <FormControlLabel
                        value="QR Code"
                        control={<Radio />}
                        label="QR Code"
                      />
                      <FormControlLabel
                        value="Bar Code"
                        label="Bar Code"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                m: "auto",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  color: "#000000bf",
                  letterSpacing: 1,
                  fontSize: "17px",
                  mb: 1,
                }}
              >
                Please provide the details of the Item:
              </Box>
              <div style={{}}>
                {items.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      marginTop: index > 0 ? 2 : 0,
                    }}
                  >
                    <input
                      style={{
                        border: "none",
                        // borderRadius: "10px",
                        background: "#8A8A8A63",
                        paddingLeft: "10px",
                        letterSpacing: 1,
                        paddingTop: "8px",
                        width: "100%",
                        paddingBottom: "10px",
                        outline: "none",
                      }}
                      placeholder="Item Details"
                      value={item}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />

                    <DeleteIcon
                      onClick={() => handleDelete(index)}
                      sx={{ cursor: "pointer", color: "#ff1010e3", mt: 0.8 }}
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <Button
                    sx={{
                      borderRadius: "10px",
                      background: "#8A8A8A63",
                      color: "black",
                      height: { md: "32px", xs: "25px" },
                      pl: { md: 2, xs: 1 },
                      pr: { md: 2, xs: 1 },
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      maxWidth: "150px",
                      minWidth: { md: "150px", xs: "50px" },
                    }}
                    onClick={handleAddMore}
                  >
                    Add More +
                  </Button>
                </Box>
              </div>
            </Box>
          </DialogContentText>
          <div style={{ display: "none" }}>
            <QRCode id="qrcode" value={uniqueCode} size={256} />
          </div>
          {barcodeVisibility && scannerType === "Bar Code" && (
            <div ref={barcodeRef}>
              <Barcode value={uniqueCode} />
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#ff0000d4",
              color: "black",
              height: { md: "32px", xs: "25px" },
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              updateProduct();
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#008000bd",
              color: "black",
              height: { md: "32px", xs: "25px" },
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "green",
                color: "white",
              },
            }}
          >
            Update Item
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontSize: { md: "25px", xs: "20px" },
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              ml: { md: 4, xs: 1 },
              mr: { md: 2, xs: 5 },
              letterSpacing: 0.5,
            }}
          >
            Products related to {name}
          </Typography>
        </Box>

        <Box
          onClick={() => {
            navigate("/v-cams");
          }}
          sx={{
            mr: { md: 4, xs: 1 },
            ml: "auto",
            cursor: "pointer",
            "&:hover": { backgroundColor: "#8A8A8A63" },
          }}
        >
          <ClearIcon sx={{ color: "red", height: "40px", width: "40px" }} />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        {/* Header */}
        <Table style={{ display: "block" }}>
          <TableHead>
            <TableRow style={{ pageBreakInside: "avoid" }}>
              <TableCell style={{ minWidth: 50 }}>Sl. no</TableCell>
              <TableCell style={{ minWidth: 300 }}>Item Name</TableCell>
              <TableCell style={{ minWidth: 200 }}>Category</TableCell>
              <TableCell style={{ minWidth: 200 }}>Sub-category</TableCell>
              <TableCell style={{ minWidth: 200 }}>Scanner Code</TableCell>
              <TableCell style={{ minWidth: 200 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
        </Table>

        {/* Body - Scrollable */}
        <Table
          style={{
            borderCollapse: "separate",
            // overflowY: "auto",
            display: "block",
            // maxHeight: 400,
          }}
        >
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} style={{ pageBreakInside: "avoid" }}>
                <TableCell style={{ minWidth: 50 }}>{index + 1}</TableCell>
                <TableCell style={{ minWidth: 300 }}>
                  {row.itemName} ({row.serialNumber})
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>{row.category}</TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  {row.subCategory}
                </TableCell>
                <TableCell
                  style={{ width: 200, cursor: "pointer" }}
                  onClick={() => handleOpenDialog(row.scannerImagePath)}
                >
                  <img
                    src={row.scannerImagePath}
                    alt=""
                    style={{ maxWidth: 200, height: 60 }}
                  />
                </TableCell>

                <TableCell style={{ width: 200 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                    }}
                  >
                    <Button
                      onClick={() => getProductById(row.itemId)}
                      sx={{
                        borderRadius: "10px",
                        background: "#008000bd",
                        color: "black",
                        height: "32px",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        minWidth: "50px",
                        "&:hover": {
                          background: "green",
                          color: "white",
                        },
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => deleteProduct(row.itemId)}
                      sx={{
                        borderRadius: "10px",
                        background: "red",
                        color: "black",
                        height: "32px",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        minWidth: "50px",
                        "&:hover": {
                          background: "red",
                          color: "white",
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GetProductsByName;
