import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { apiList, invokeApi, invokeFormDataApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// Use columnsWithRedHeader in your DataGrid component

const User = () => {
  const [cookies] = useCookies();
  const [open, setOpen] = useState(false);

  const [userid, setuserid] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [mail, setMail] = useState();
  const [role, setRole] = useState();
  const [profilePhoto, setprofilePhoto] = useState();
  const [profileUrl, setprofileUrl] = useState();
  const [tableData, setTableData] = useState([]);
  console.log(tableData);
  const [userUpdated, setUserUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [autoNameChange, setautoNameChange] = useState("");

  const [user, setuser] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [handleUpdateUser, sethandleUpdateUser] = useState(false);
  const [profileUploadMessage, setprofileUploadMessage] = useState("");
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const uploadImageGetPath = async (file) => {
    setprofileUploadMessage("Loading...");
    try {
      let formData = new FormData();
      formData.append("userImage", file);
      formData.append("path", "v_cams_gallery");

      let response = await invokeFormDataApi(
        config.apiDomains + apiList.uploadProfilePicture,
        formData,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setprofileUrl(response.data.imagePath);
        setprofileUploadMessage("Profile photo uploaded successfuly...");
      } else {
        console.error(
          "Error while uploading image. HTTP status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error while uploading image:", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(selectedFile);

    // Update the profilePhoto state with the image URL
    setprofilePhoto(imageUrl);
    uploadImageGetPath(selectedFile);
  };
  const addUser = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        fullName: name,
        mobileNumber: phone,
        password,
        email: mail,
        role,
        profilePicture: profileUrl,
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.addUser,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setOpen(false);
        setUserUpdated(true);
        setprofileUrl("");
        setprofileUploadMessage("");
        setOpen(false);
        setsnackMessage("User added sucessfully");
        setsnackOpen(true);
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };
  const updateUser = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        id: userid,
        fullName: name,
        mobileNumber: phone,
        email: mail,
        role,
        profilePicture: profileUrl
          ? profileUrl
          : profilePhoto
          ? profilePhoto
          : "",
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.updateUser,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setUserUpdated(true);
        setsnackMessage("User updated successfully");
        setprofileUploadMessage("");
        setsnackOpen(true);
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };
  const changePassword = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { userId: userid, newPassword: password };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.changePassword,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setUserUpdated(true);
        setsnackMessage("Password updated successfully");
        setprofileUploadMessage("");
        setsnackOpen(true);
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };
  const getUserById = async (id) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setprofileUploadMessage("");
      setLoading(true);
      let params = { id };

      let response = await invokeApi(
        config.apiDomains + apiList.getUserById,
        params,
        cookies,
        "get"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        if (response) {
          setName(response.data.userData.fullName);
          setPhone(response.data.userData.mobileNumber);
          setMail(response.data.userData.email);
          setRole(response.data.userData.role);
          setuserid(response.data.userData.id);
          setprofilePhoto(response.data.userData.profilePicture);
          setOpen(true);
          sethandleUpdateUser(true);
        }
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };
  const deleteUser = async (id) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { id };

      let response = await invokeApi(
        config.apiDomains + apiList.deleteUser,
        params,
        cookies,
        "get"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setUserUpdated(true);
        setsnackMessage("User deleted successfully");
        setsnackOpen(true);
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  useEffect(() => {
    const getUser = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        if (user === "") setLoading(true);
        let params = { userName: user };

        let response = await invokeApi(
          config.apiDomains + apiList.getUserBySearch,
          params,
          cookies
        );

        if (response) {
          if (response.status >= 200 && response.status < 300) {
            setLoading(false);
            setTableData(response.data.userData);
            setUserUpdated(false);
          } else {
            alert(
              "Something went wrong while saving property data. Please try again later!!"
            );
            setLoading(false);
          }
          setLoading(false);
        }
        setLoading(false);
      } else navigate("/");
    };

    getUser();
  }, [cookies, userUpdated, navigate, handleUpdateUser, user]);

  // useEffect(() => {
  //   const getUserBySearch = async (input) => {
  //     if (user === "") setLoading(true);
  //     else setLoading(false);
  //     if (new Date(cookies.vcams_sess.expiry) > new Date()) {
  //       let params = { customerName: user };

  //       let response = await invokeApi(
  //         config.apiDomains + apiList.getUserBySearch,
  //         params,
  //         cookies
  //       );

  //       if (response.status >= 200 && response.status < 300) {
  //         setLoading(false);
  //         setUserUpdated(false);
  //         setTableData(response.data.userData);
  //       }
  //       setLoading(false);
  //       setUserUpdated(false);
  //     } else navigate("/");
  //     setLoading(false);
  //     setUserUpdated(false);
  //   };
  //   getUserBySearch();
  // }, [cookies, user, navigate]);

  const handleAddNewUser = () => {
    setOpen(true);
    sethandleUpdateUser(false);
    setName("");
    setMail("");
    setPassword("");
    setPhone("");
    setRole("");
    setprofilePhoto("");
    setprofileUrl("");
    setprofileUploadMessage("");
    setautoNameChange("ADD");
  };
  return (
    <Box sx={{ mt: { md: 5.5, xs: 1, minHeight: "100vh" } }}>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "User deleted successfully" ? "red" : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              ml: 8,
            }}
          >
            User
          </Typography>
        </Box>

        <Avatar
          alt={cookies?.vcams_user.name}
          src={cookies?.vcams_user.photo}
          sx={{
            width: 72,
            height: 72,
            ml: 8,
            bgcolor: "red",
            fontSize: "30px",
            letterSpacing: 1,
            display: { md: "flex", xs: "none" },
          }}
        >
          {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
        </Avatar>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { md: "96%", xs: "98%" },
          m: "auto",
          mt: 5,
          flexDirection: { md: "row", xs: "column" },
          gap: { md: 0, xs: 2 },
        }}
      >
        <Button
          sx={{
            borderRadius: "10px",
            height: "30px",
            pl: { md: 2, xs: 1 },
            pr: { md: 2, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            maxWidth: { md: "180px", xs: "120px" },
            minWidth: { md: "180px", xs: "120px" },
            background: "#ff0000d4",
            color: "black",
            "&:hover": {
              background: "red",
              color: "white",
            },
          }}
          onClick={handleAddNewUser}
        >
          New User +
        </Button>
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "black",
              textAlign: "center",
              letterSpacing: "8px",
            },
            "& .MuiInputBase-input": {
              backgroundColor: "#8A8A8A63",
              height: ".01em",
              borderRadius: "10px",
            },
            mb: 2,
            mr: 1,
            ml: { md: 1, xs: "auto" },
            maxWidth: "170px",
          }}
          value={user}
          placeholder="User Name"
          onChange={(e) => {
            setuser(e.target.value);
          }}
        />
      </Box>
      <Dialog
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 700,
            minWidth: { md: 700, xs: 250 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              {autoNameChange === "UPDATE"
                ? "Update User"
                : autoNameChange === "CHANGEPASSWORD"
                ? "Reset Password"
                : "Add New User"}
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              <ClearIcon
                onClick={() => setOpen(false)}
                sx={{
                  cursor: "pointer",
                  mt: 0.8,
                  "&:hover": { backgroundColor: "#8A8A8A63" },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {autoNameChange !== "CHANGEPASSWORD" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  mb: 5,
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Avatar
                    alt={name ? name : ""}
                    src={profilePhoto}
                    sx={{
                      width: 85,
                      height: 85,
                      cursor: "pointer",
                      border: isHovered
                        ? "2px solid blue"
                        : "2px solid transparent",
                    }}
                    onClick={handleAvatarClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  />
                  {/* Hidden file input triggered by the avatar click */}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />{" "}
                </Box>
                <Box>{profileUploadMessage}</Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {autoNameChange !== "CHANGEPASSWORD" && (
                <>
                  <TextField
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "black",
                        textAlign: "center",
                        letterSpacing: "8px",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "#8A8A8A63",
                        height: ".01em",
                        minWidth: { md: 230, xs: 0 },
                      },
                      mb: 2,
                      mr: 1,
                      ml: 1,
                    }}
                    placeholder="Name"
                  />

                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "black",
                        textAlign: "center",
                        letterSpacing: "8px",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "#8A8A8A63",
                        height: ".01em",
                        minWidth: { md: 230, xs: 0 },
                      },
                      mb: 2,
                      mr: 1,
                      ml: 1,
                    }}
                    placeholder="Contact no."
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "black",
                        textAlign: "center",
                        letterSpacing: "8px",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "#8A8A8A63",
                        height: ".01em",
                        minWidth: { md: 230, xs: 0 },
                      },
                      mb: 2,
                      mr: 1,
                      ml: 1,
                    }}
                    placeholder="Email ID"
                    onChange={(e) => setMail(e.target.value)}
                    value={mail}
                  />

                  <select
                    id="cars"
                    name="cars"
                    style={{
                      border: "none",
                      background: "#8A8A8A63",
                      paddingLeft: "10px",
                      maxHeight: "33.5px",
                      minHeight: "33px",
                      minWidth: "185px",
                      letterSpacing: 1,
                    }}
                    onChange={(event) => setRole(event.target.value)}
                    value={role}
                  >
                    <option value="volvo" style={{ color: "red" }}>
                      Select Role
                    </option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </>
              )}
              {autoNameChange !== "UPDATE" && (
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "black",
                      textAlign: "center",
                      letterSpacing: "8px",
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "#8A8A8A63",
                      height: ".01em",
                      minWidth: { md: 230, xs: 0 },
                    },
                    mb: 2,
                    mr: 1,
                    ml: 1,
                  }}
                  placeholder="Password"
                  // type={showPassword1 ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#ff0000d4",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              if (autoNameChange === "UPDATE") {
                setOpen(false);
                updateUser();
              } else if (autoNameChange === "ADD") {
                setOpen(false);
                addUser();
              } else if (autoNameChange === "CHANGEPASSWORD") {
                setOpen(false);
                changePassword();
              }
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#008000bd",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "green",
                color: "white",
              },
            }}
          >
            {autoNameChange === "ADD"
              ? " Add User"
              : autoNameChange === "CHANGEPASSWORD"
              ? "Change Password"
              : "Update User"}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: { md: "96%", xs: "96%" },
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          m: "auto",
          mt: 2,
          mb: 8,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",
            mt: 2,
            mb: 2,

            "& .MuiDataGrid-withBorderColor": {
              border: "none !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root": {
              color: "black !important",
              display: "none !important",
            },

            "& .css-wop1k0-MuiDataGrid-footerContainer": {
              display: "none !important",
            },
            "& .css-j204z7-MuiFormControlLabel-root": {
              display: "none !important",
            },

            "& .css-204u17-MuiDataGrid-main ": {
              pl: "20px !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root ": {
              pl: "25px !important",
            },
            "& .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:hover, .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered":
              {
                backgroundColor: "white !important",
              },
          }}
          aria-expanded="true"
        >
          <Box sx={{ width: 0.99 }}>
            <Box>
              <TableContainer component={Paper}>
                {/* Header */}
                <Table>
                  <TableHead>
                    <TableRow style={{ pageBreakInside: "avoid" }}>
                      <TableCell style={{ minWidth: 50 }}>Sl. no</TableCell>
                      <TableCell style={{ minWidth: 250 }}>Full Name</TableCell>
                      <TableCell style={{ minWidth: 200 }}>Ph. no</TableCell>
                      {/* <TableCell style={{ minWidth: 200 }}>Mail ID</TableCell> */}
                      <TableCell style={{ minWidth: 200 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>

                {/* Body - Scrollable */}
                <Table
                  style={{
                    borderCollapse: "separate",
                    // overflowY: "auto",
                    // display: "block",
                    // maxHeight: 400,
                  }}
                >
                  <TableBody>
                    {tableData.map((row, index) =>
                      // Check if the status is 'inactive'
                      row.status === "Inactive" ? null : (
                        <TableRow
                          key={index}
                          style={{ pageBreakInside: "avoid" }}
                        >
                          <TableCell style={{ minWidth: 50 }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ minWidth: 250 }}>
                            {row.userName}
                          </TableCell>
                          <TableCell style={{ minWidth: 200 }}>
                            {row.contactNumber}
                          </TableCell>
                          {/* <TableCell style={{ minWidth: 200 }}>
                            {row.email}
                          </TableCell> */}
                          <TableCell style={{ minWidth: 200 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 1,
                              }}
                            >
                              <Button
                                onClick={() => {
                                  getUserById(row.userId);
                                  setautoNameChange("UPDATE");
                                }}
                                sx={{
                                  borderRadius: "10px",
                                  background: "#008000bd",
                                  color: "black",
                                  height: "32px",
                                  textTransform: "none",
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                  "&:hover": {
                                    background: "green",
                                    color: "white",
                                  },
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "10px",
                                  background: "red",
                                  color: "black",
                                  height: "32px",
                                  textTransform: "none",
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                  "&:hover": {
                                    background: "red",
                                    color: "white",
                                  },
                                }}
                                onClick={() => deleteUser(row.userId)}
                              >
                                Delete
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "10px",
                                  background: "#545de4f7",
                                  color: "black",
                                  height: "32px",
                                  textTransform: "none",
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                  "&:hover": {
                                    background: "#545de4f7",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  getUserById(row.userId);
                                  setautoNameChange("CHANGEPASSWORD");
                                  setPassword("");
                                }}
                              >
                                Password
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        color="primary" aria-expanded="true"
        // startIcon={<SaveAltIcon />}
        onClick={handleExportExcelClick}
      >
        Export as Excel
      </Button> */}
    </Box>
  );
};

export default User;
