import React, { createContext, useEffect, useState } from "react";

export const userContext = createContext();

export const UserContextProvider = (props) => {
  const [itemDetails, setItemDetails] = useState([]);
  const [printSignal, setprintSignal] = useState(false);
  const [dcNumber, setdcNumber] = useState("");
  const [dcIssuedBy, setdcIssuedBy] = useState("");
  const [dcCheckedBy, setdcCheckedBy] = useState("");
  const [remarks, setremarks] = useState("");
  const [dcOpeningType, setdcOpeningType] = useState("");
  const [updateItemDetails, setupdateItemDetails] = useState();
  const [dcCreator, setdcCreator] = useState("");
  const [expiryTime, setexpiryTime] = useState();
  const [dcId, setdcId] = useState();
  const [searchByCategory, setsearchByCategory] = useState("Cameras");
  const [searchBySubCategory, setsearchBySubCategory] = useState("");
  
  const [labale, setlabale] = useState("Dashboard");
  const [scanVisible, setscanVisible] = useState(true);

  const [activeMenu, setActiveMenu] = useState("DASHBOARD");
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    customerId:1,
    mobileNumber: "",
    packTime: "",
    dateOut: "",
    timeOut: "",
    shootDate: "",
    deliveredBy: "",
    collectedBy: "",
    crew: "",
  });
 

  useEffect(()=>{})

  return (
    <userContext.Provider
      value={{
        setprintSignal,
        printSignal,
        itemDetails,
        setItemDetails,
        customerInfo,
        setCustomerInfo,
        setdcCreator,
        activeMenu,
        dcCreator,labale, setlabale,
        setActiveMenu,searchByCategory, setsearchByCategory,searchBySubCategory, setsearchBySubCategory,
        dcNumber,
        setdcNumber,
        dcIssuedBy,
        setdcIssuedBy,
        dcCheckedBy,
        setdcCheckedBy,
        remarks,
        setremarks,
        updateItemDetails,
        setupdateItemDetails,
        dcOpeningType,
        setdcOpeningType,expiryTime, setexpiryTime,dcId, setdcId,scanVisible, setscanVisible
      }}
    >
      {props.children}
    </userContext.Provider>
  );
};
