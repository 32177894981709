import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Avatar,
  Autocomplete,
} from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useNavigate } from "react-router-dom";

const Customer = () => {
  const componentRef = useRef();
  const [cookies] = useCookies();
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [handleUpdateCustomer, setHandleUpdateCustomer] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [customer, setcustomer] = useState("");
  const [autoNameChange, setautoNameChange] = useState("");
  const [autoReload, setautoReload] = useState("AD");

  const addCustomer = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);setautoReload('');
      let params = {
        customerName: customerName,
        contactNumber: contactNumber,
        email: email,
        address: address,
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.addCustomer,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        setLoading(false);setautoReload("2r3fewfewfewffEGG");
        setOpen(false);
        handleAddNewUser();
        setsnackMessage("Customer added successfully");
        setsnackOpen(true);
      } else {
        alert(
          "Something went wrong while saving customer data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const getCustomer = async (customerId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      
      let params = {
        customerId,
      };

      let response = await invokeApi(
        config.apiDomains + apiList.getCustomer,
        params,
        cookies,
        "get"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        
        setCustomerName(response.data.customerData.customerName);
        setCustomerId(response.data.customerData.customerId);
        setContactNumber(response.data.customerData.contactNumber);
        setAddress(response.data.customerData.address);
        setEmail(response.data.customerData.email);
        setOpen(true);
        setHandleUpdateCustomer(true);
      } else {
        alert("Something went wrong while fetching data. Please try again!!");
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const updateCustomer = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      setautoReload('');
      let params = {
        customerId,
        customerName,
        contactNumber,
        email,
        address,
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.updateCustomer,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        handleAddNewUser();
        setsnackMessage("Customer updated successfully");
        setsnackOpen(true);
        setautoReload("EGgegewsG");
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const deleteCustomer = async (customerId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      setautoReload('');
      let params = { customerId };

      let response = await invokeApi(
        config.apiDomains + apiList.deleteCustomer,
        params,
        cookies,
        "get"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setsnackMessage("Customer deleted successfully");
        setsnackOpen(true);
        setautoReload("EGGewfwfeX");
      } else {
        alert(
          "Something went wrong while deleting customer. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  useEffect(() => {
    const fetchNameSuggestions = async (input) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = { customerName: "" };

        let response = await invokeApi(
          config.apiDomains + apiList.getCustomerByName,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setNameSuggestions(response.data.customerData);
        }
      } else navigate("/");
    };
    fetchNameSuggestions();
  }, [cookies, navigate, autoReload]);
  useEffect(() => {
    const getCustomerBySearch = async (input) => {
      if (customer === "") setLoading(true);
      else setLoading(false);
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = { customerName: customer };

        let response = await invokeApi(
          config.apiDomains + apiList.getCustomerBySearch,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          setTableData(response.data.customerData);
        }
        setLoading(false);
      } else navigate("/");
      setLoading(false);
    };
    getCustomerBySearch();
  }, [cookies, customer, navigate, autoReload]);

  const handleAddNewUser = () => {
    setCustomerName("");
    setContactNumber("");
    setEmail("");
    setAddress("");
  };

  return (
    <Box sx={{ mt: { md: 5.5, xs: 1, minHeight: "100vh" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              ml: 8,
            }}
          >
            Client
          </Typography>
        </Box>

        <Avatar
          alt={cookies?.vcams_user.name}
          src={cookies?.vcams_user.photo}
          sx={{
            width: 72,
            height: 72,
            ml: 8,
            bgcolor: "red",
            fontSize: "30px",
            letterSpacing: 1,
            display: { md: "flex", xs: "none" },
          }}
        >
          {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
        </Avatar>
      </Box>

      <Grid
        container
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { md: 8, xs: 5 },
        }}
      ></Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { md: "96%", xs: "98%" },
          m: "auto",
          flexDirection: { md: "row", xs: "column" },
          gap: { md: 0, xs: 2 },
        }}
      >
        <Button
          sx={{
            borderRadius: "10px",
            height: "30px",
            pl: { md: 2, xs: 1 },
            pr: { md: 2, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            maxWidth: "180px",
            minWidth: "180px",
            background: "#ff0000d4",
            color: "black",
            "&:hover": {
              background: "red",
              color: "white",
            },
          }}
          onClick={() => {
            setOpen(true);
            setHandleUpdateCustomer(false);
            handleAddNewUser();
            setautoNameChange("ADD");
          }}
        >
          New Client +
        </Button>
        <Autocomplete
          id="outlined-select-Name"
          sx={{
            marginRight: { md: "0px", xs: "auto" },
            minWidth: 230,
            mt: { md: -1, xs: 2 },
          }}
          options={nameSuggestions.map((suggestion) => suggestion.customerName)}
          value={customer || ""}
          freeSolo={true}
          onChange={(event, newValue) => {
            setcustomer(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={customer || ""}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "black",
                  textAlign: "center",
                  letterSpacing: "8px",
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "#8A8A8A63",
                  height: "17px",
                  borderRadius: "10px",
                },
                mb: 2,
                mr: 1,
                ml: 1,
              }}
              placeholder="Client Name"
              onChange={(event) => {
                setcustomer(event.target.value);
              }}
            />
          )}
        />
      </Box>
      <Dialog
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": { maxWidth: "100%" },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              {autoNameChange === "ADD" ? "Add New" : "Update"} Client
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              <ClearIcon
                onClick={() => setOpen(false)}
                sx={{
                  cursor: "pointer",
                  mt: 0.8,
                  "&:hover": { backgroundColor: "#8A8A8A63" },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder":
                    {
                      color: "black",
                      textAlign: "center",
                      letterSpacing: "8px",
                    },
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    backgroundColor: "#8A8A8A63",
                    height: ".01em",
                  },
                  mb: 2,
                  mr: 1,
                  ml: 1,
                }}
                placeholder="Name"
              />
              <TextField
                onChange={(e) => setContactNumber(e.target.value)}
                value={contactNumber}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder":
                    {
                      color: "black",
                      textAlign: "center",
                      letterSpacing: "8px",
                    },
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    backgroundColor: "#8A8A8A63",
                    height: ".01em",
                  },
                  mb: 2,
                  mr: 1,
                  ml: 1,
                }}
                placeholder="Contact Number"
              />
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder":
                    {
                      color: "black",
                      textAlign: "center",
                      letterSpacing: "8px",
                    },
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    backgroundColor: "#8A8A8A63",
                    height: ".01em",
                  },
                  mb: 2,
                  mr: 1,
                  ml: 1,
                }}
                placeholder="Email ID"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <textarea
                style={{
                  width: "95%",
                  height: "40px",
                  background: "#8A8A8A63",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  padding: "10px",
                  fontSize: "14px",
                  letterSpacing: 0.8,
                }}
                placeholder="Full Adress"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              {/* <div>
                {items.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                      marginTop: index > 0 ? 2 : 0,
                    }}
                  >
                    <input
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        background: "#8A8A8A63",
                        paddingLeft: "10px",
                        letterSpacing: 1,
                        paddingTop: "8px",
                        width: "100%",
                        paddingBottom: "10px",
                        outline: "none",
                      }}
                      placeholder="Item Details"
                      value={item}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />

                    <ClearIcon
                      onClick={() => handleDelete(index)}
                      sx={{ cursor: "pointer", color: "red", mt: 0.8 }}
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <Button
                    sx={{
                      borderRadius: "10px",
                      background: "#8A8A8A63",
                      color: "black",
                      height: "32px",
                      pl: { md: 2, xs: 1 },
                      pr: { md: 2, xs: 1 },
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      maxWidth: "150px",
                      minWidth: "150px",
                    }}
                    onClick={handleAddMore}
                  >
                    Add More +
                  </Button>
                </Box>
              </div> */}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#ff0000d4",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              if (handleUpdateCustomer) {
                setOpen(false);
                updateCustomer();
              } else if (!handleUpdateCustomer) {
                setOpen(false);
                addCustomer();
              }
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#008000bd",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "green",
                color: "white",
              },
            }}
          >
            {autoNameChange === "ADD" ? "Add" : "Update"} Client
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: { md: "96%", xs: "96%" },
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          m: "auto",
          mt: 2,
          mb: 8,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",
            mt: 2,
            mb: 2,

            "& .MuiDataGrid-withBorderColor": {
              border: "none !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root": {
              color: "black !important",
            },

            "& .css-wop1k0-MuiDataGrid-footerContainer": {
              display: "none !important",
            },
            "& .css-j204z7-MuiFormControlLabel-root": {
              display: "none !important",
            },
            "& .css-204u17-MuiDataGrid-main ": {
              pl: "20px !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root ": {
              pl: "25px !important",
            },
            "& .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:hover, .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered":
              {
                backgroundColor: "white !important",
              },
          }}
          aria-expanded="true"
        >
          <Box sx={{ width: 0.99 }}>
            <TableContainer component={Paper} ref={componentRef}>
              {/* Header */}
              <Table style={{ display: "block" }}>
                <TableHead>
                  <TableRow style={{ pageBreakInside: "avoid" }}>
                    <TableCell style={{ width: 50 }}>Sl. no</TableCell>
                    <TableCell style={{ width: 250 }}>Client Name</TableCell>
                    <TableCell style={{ width: 250 }}>Client code</TableCell>
                    <TableCell style={{ width: 250 }}>Contact Number</TableCell>
                    {/* <TableCell style={{ width: 250 }}>Address</TableCell> */}
                    <TableCell style={{ width: 250 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {/* Body - Scrollable */}
              <Table
                style={{
                  borderCollapse: "separate",
                  // overflowY: "auto",
                  display: "block",
                  // maxHeight: 400,
                }}
              >
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index} style={{ pageBreakInside: "avoid" }}>
                      <TableCell style={{ width: 50 }}>{index + 1}</TableCell>
                      <TableCell style={{ width: 250 }}>
                        {row.customerName}
                      </TableCell>
                      <TableCell style={{ width: 250 }}>
                        {row.customerCode}
                      </TableCell>
                      <TableCell style={{ width: 250 }}>
                        {row.contactNumber}
                      </TableCell>
                      {/* <TableCell style={{ width: 250 }}>{row.email}</TableCell>
                      <TableCell style={{ width: 250 }}>
                        {row.address}
                      </TableCell> */}
                      <TableCell style={{ minWidth: 250 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          <Button
                            onClick={() => {
                              setautoNameChange("UPDATE");
                              getCustomer(row.customerId);
                              setHandleUpdateCustomer(true);
                            }}
                            sx={{
                              borderRadius: "10px",
                              background: "#008000bd",
                              color: "black",
                              height: "32px",
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              minWidth: "50px",
                              "&:hover": {
                                background: "green",
                                color: "white",
                              },
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            sx={{
                              borderRadius: "10px",
                              background: "red",
                              color: "black",
                              height: "32px",
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              minWidth: "50px",
                              "&:hover": {
                                background: "red",
                                color: "white",
                              },
                            }}
                            onClick={() => deleteCustomer(row.customerId)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        color="primary" aria-expanded="true"
        // startIcon={<SaveAltIcon />}
        onClick={handleExportExcelClick}
      >
        Export as Excel
      </Button> */}

      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "Customer deleted successfully"
                  ? "red"
                  : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
    </Box>
  );
};

export default Customer;
