import axios from "axios";
import { config } from "../config/Config";
const packageJson = require("../../../package.json");



export const invokeApi = async (url, params, cookies) => {
  
    try {
      let headers = {
        "Content-Type": "application/json",
        appversion: packageJson.version,
        platform: "web",
      };
      if (
        cookies &&
        cookies[config.cookieName] &&
        cookies[config.cookieName].token &&
        cookies[config.cookieName].loginUserId
      ) {
        headers.Authorization = "Bearer " + cookies[config.cookieName].token;
        headers.loginUserId = cookies[config.cookieName].loginUserId;
      }
      if (
        cookies &&
        cookies[config.sessionCookie] &&
        cookies[config.sessionCookie].sessionId
      ) {
        headers.sessionId = cookies[config.sessionCookie].sessionId;
      }
      return await axios.post(url, params, { headers: headers });
    } catch ({ response }) {
      return response;
    }
  } 
 


export const invokeFormDataApi = async (url, formData, cookies) => {
  try {
    let headers = {
      "Content-Type": "multipart/form-data",
      appversion: packageJson.version,
      platform: "web",
    };
    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;
      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }
    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }
    return await axios.post(url, formData, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const apiList = {
  //Login
  login: "/login",
  addUser: "/addUser",
  addProduct: "/addProduct",
  uploadFile: "/uploadScannerImage",
  updateProductScanner: "/updateProductScanner",
  getProductFromCode: "/getProductFromCode",
  getProductsByCategory: "/getProductsByCategory",
  getUsers: "/getUsers",
  getUserById: "/getUser",
  changePassword: "/changePassword",
  updateUser: "/updateUser",
  deleteUser: "/deleteUser",
  uploadProfilePicture: "/uploadProfilePicture",
  getProduct: "/getProduct",
  getProducts: "/getProducts",
  updateProduct: "/updateProduct",
  deleteProduct: "/deleteProduct",
  addCategory: "/addCategory",
  getCategories: "/getCategories",
  getCategory: "/getCategory",
  updateCategory: "/updateCategory",
  deleteCategory: "/deleteCategory",
  addDC: "/addDC",
  getDCs: "/getDCs",
  getDC: "/getDC",
  updateDC: "/updateDC",
  returnDC: "/returnDC",
  getDCForReturn: "/getDCForReturn",

  addCustomer: "/addCustomer",
  getCustomers: "/getCustomers",
  getCustomer: "/getCustomer",
  updateCustomer: "/updateCustomer",
  deleteCustomer: "/deleteCustomer",
  getCustomerByName: "/getCustomerByName",
  getDCsPageCount: "/getDCsPageCount",
  getDcByDate: "/getDCsByDate",
  getDcByCustomer: "/getDCsByCustomer",
  getDcByItems: "/getDCsByItems",
  getDCbyDcNumber: "/getDCsByDCNumber",
  getDashboard: "/getDashboard",
  getDashboardGraph: "/getDashboardGraph",
  getDashboardPercent: "/getDashboardPercent",
  getDeletedProducts: "/getDeletedProducts",
  revertDeleteProduct: "/revertDeleteProduct",
  getCustomerBySearch: "/getCustomerBySearch",
  getUserBySearch: "/getUserBySearch",
  getCategoriesId: "/getCategoriesId",
};
