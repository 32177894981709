import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import SideNav from "./components/pages/Sidenav";
import GetProductsByName from "./components/pages/screens/GetProductsByName";
import CheckDc from "./components/pages/screens/CheckingDc";
import RecycleInventory from "./components/pages/screens/RecycleInventory";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/v-cams" element={<SideNav />} />
        <Route path="/product-by-name/:name" element={<GetProductsByName />} />
        <Route path="/check-dc/:id" element={<CheckDc />} />
        <Route path="/recycle-inventory" element={<RecycleInventory />} />
      </Routes>
    </Router>
  );
}

export default App;
