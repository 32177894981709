import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuIcon from "@mui/icons-material/Menu";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import CategoryIcon from "@mui/icons-material/Category";
import Dashbord from "./screens/Dashbord";
import Customer from "./screens/Customer";
import Dc from "./screens/Dc";
import Category from "./screens/Category";
import Inventory from "./screens/Inventory";
import User from "./screens/User";
import { userContext } from "../useContext/CreateContext";
import { useCookies } from "react-cookie";
import logo from "../../images/vcams-logo.png";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@material-ui/core";

export default function SideNav() {
  const [open, setOpen] = useState(true);
  const [cookies] = useCookies();
  const { activeMenu, setActiveMenu, labale, setlabale , setscanVisible} =
    React.useContext(userContext);
  const [anchorMenu, setanchorMenu] = useState(null);
  const navigate = useNavigate();

  const menuItems = [
    { icon: <DashboardIcon />, text: "Dashboard", to: "DASHBOARD" },
    { icon: <InventoryIcon />, text: "Inventory", to: "INVENTORY" },
    { icon: <QrCode2Icon />, text: "User", to: "USER" },
    { icon: <PermIdentityIcon />, text: "Client", to: "CUSTOMER" },
    { icon: <FireTruckIcon />, text: "DC", to: "DC" },
    { icon: <CategoryIcon />, text: "Category", to: "CATEGORY" },
    { icon: <CategoryIcon />, text: "logout", to: "/v-cams" },
  ];

  const handleLogout = () => {
    // removeCookie("vcams_user");
    navigate("/");
  };
  return (
    <Grid container sx={{ display: "flex", background: "#F0F0F0" }}>
      <Grid md={open ? 2.5 : 1.5} item variant="permanent">
        <Box
          sx={{
            borderRadius: "41px",
            position: "fixed",
            width: open ? "16.5%" : 70,
            background:
              "linear-gradient(187deg, #000 6.8%, rgba(0, 0, 0, 0.50) 161.49%)",
            m: 3,
            height: "93vh",
            display: { md: "block", xs: "none" },
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!open && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  mb: 4,
                }}
              >
                <Box sx={{ mt: 2 }}>
                  <Button onClick={() => setOpen(!open)}>
                    <EastIcon
                      sx={{ height: "auto", width: "28px", color: "white" }}
                    />
                  </Button>
                </Box>
                <Box component="img" src={logo} sx={{ width: 50 }} />
              </Box>
            )}
            {open && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  mt: 4,
                  mb: 4,
                }}
              >
                <Button
                  onClick={() => setOpen(!open)}
                  sx={{
                    ml: "auto",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <KeyboardArrowLeftIcon
                    sx={{
                      height: "auto",
                      width: "36px",
                      color: "white",
                      p: 0,
                      mb: 2,
                      mt: -2,
                    }}
                  />
                </Button>
                <Box component="img" src={logo} sx={{ width: 150 }} />
              </Box>
            )}
          </Box>

          <List>
            {menuItems.map((item, index) =>
              // Check if the condition is met before rendering the ListItem
              cookies.vcams_user?.role !== "admin" &&
              item.text === "User" ? null : (
                <ListItem
                  disablePadding
                  key={index}
                  onClick={() => {
                    if (item.to === "/v-cams") {
                      handleLogout();
                    } else setActiveMenu(item.to);
                  }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "red",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "white",
                        fontSize: "30px",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          display: { md: "none", xs: "flex" },
          flexDirection: "row",
          background:
            "linear-gradient(187deg, #000 6.8%, rgba(0, 0, 0, 0.50) 161.49%)",
          pb: 2,
        }}
      >
        <Grid item sx={{ mt: 2, ml: 1 }}>
          <Typography
            sx={{
              color: "white",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {labale}
          </Typography>
        </Grid>

        <Grid
          item
          sx={{
            ml: "auto",
            mr: 1,
            mt: 2,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Mulish, sans-serif",
              fontSize: { md: "18px", xs: "13px" },
              mr: 1,
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => setanchorMenu(event.currentTarget)}
          >
            <Box>
              <MenuIcon sx={{ color: "white" }} />
            </Box>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorMenu}
            keepMounted
            open={Boolean(anchorMenu)}
            onClose={() => setanchorMenu(null)}
            style={{ fontSize: { xs: "10px" } }}
          >
            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                setActiveMenu("DASHBOARD");
                setlabale("Dashboard");
                setanchorMenu(null);
              }}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                setActiveMenu("INVENTORY");
                setlabale("Inventory");
                setanchorMenu(null);
              }}
            >
              Inventory
            </MenuItem>

            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                setActiveMenu("USER");
                setlabale("User");
                setanchorMenu(null);
              }}
            >
              User
            </MenuItem>
            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                setActiveMenu("CUSTOMER");
                setlabale("Client");
                setanchorMenu(null);
              }}
            >
              Client
            </MenuItem>
            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                setActiveMenu("DC");
                setlabale("DC");
                setanchorMenu(null);setscanVisible(false)
              }}
            >
              DC
            </MenuItem>
            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                setActiveMenu("CATEGORY");
                setlabale("Category");
                setanchorMenu(null);
              }}
            >
              Category
            </MenuItem>
            <MenuItem
              style={{ fontSize: { xs: "10px" } }}
              onClick={() => {
                navigate("/");
              }}
            >
              Logout
            </MenuItem>
          </Menu>
          <Avatar
            alt={cookies?.vcams_user.name}
            src={cookies?.vcams_user.photo}
            sx={{
              width: 72,
              height: 72,
              ml: 8,
              bgcolor: "red",
              fontSize: "30px",
              letterSpacing: 1,
              display: { md: "flex", xs: "none" },
            }}
          >
            {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
          </Avatar>
        </Grid>
      </Grid>
      <Grid item md={open ? 9.5 : 10.5} xs={12} sx={{ ml: { md: -2, xs: 0 } }}>
        {activeMenu === "DASHBOARD" && <Dashbord open={open} />}
        {activeMenu === "CUSTOMER" && <Customer />}
        {activeMenu === "DC" && <Dc />}
        {activeMenu === "USER" && <User />}
        {activeMenu === "INVENTORY" && <Inventory />}
        {activeMenu === "CATEGORY" && <Category />}
      </Grid>
    </Grid>
  );
}
