import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Chart from "react-apexcharts";
import { useCookies } from "react-cookie";
import { config } from "../../config/Config";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { useNavigate } from "react-router-dom";

export const HorizontalBarChart = ({ fillPercentage, width, fillColor }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine the effective width based on screen size
  const effectiveWidth = isXsScreen ? width.xs || width : width.md || width;

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: effectiveWidth,
        position: "relative",
        backgroundColor: "#ccc",
        borderRadius: 50,
      }}
    >
      <Box
        sx={{
          width: `${fillPercentage}%`,
          height: "100%",
          backgroundColor: fillColor,
          borderRadius: 50,
        }}
      />
    </Box>
  );
};

export const Counts = ({ lable, count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          color: "#000",
          textAlign: "center",
          fontSize: { md: "20px", xs: "12px" },
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          // maxWidth: { md: "200px", xs: "75px" },
        }}
      >
        {lable}
      </Box>
      <Box
        sx={{
          color: "#000",
          textAlign: "center",
          fontSize: { md: "80px", xs: "22px" },
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        {count}
      </Box>
    </Box>
  );
};

export const CircularChart = ({ fillPercentage, size, fillColor }) => {
  const theme = useTheme();

  // Extracting size values for different breakpoints
  const mdSize = size.md || size;
  const xsSize = size.xs || size;

  // Getting the current screen width
  const screenWidth = window.innerWidth;

  // Using xsSize if the screen width is below the 'sm' breakpoint
  const effectiveSize =
    screenWidth < theme.breakpoints.values.sm ? xsSize : mdSize;

  const radius = effectiveSize - 10;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset =
    circumference - (fillPercentage / 100) * circumference;

  return (
    <svg height="100%" width="100%">
      <circle
        r={radius}
        cx={effectiveSize}
        cy={effectiveSize}
        fill="transparent"
        stroke="#ccc"
        strokeWidth="4"
        transform={`rotate(-90 ${effectiveSize} ${effectiveSize})`}
      />
      <circle
        r={radius}
        cx={effectiveSize}
        cy={effectiveSize}
        fill="transparent"
        stroke={fillColor}
        strokeWidth="4"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        transform={`rotate(-90 ${effectiveSize} ${effectiveSize})`}
      />
    </svg>
  );
};

const Dashboard = () => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      series: [
        {
          name: "Series 1",
          data: [70, 60, 40, 50, 0],
        },
      ],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
          colorStops: [
            {
              offset: 0,
              color: "#FF0000", // Start with red color
            },
            {
              offset: 100,
              color: "#FFFFFF", // End with white color
            },
          ],
        },
      },
      xaxis: {
        categories: [
          "01 Jan",
          "02 Feb",
          "03 Mar",
          "04 Apr",
          "05 May",
          "06 Jun",
          "07 Jul",
          "08 Aug",
          "09 Sep",
          "10 Oct",
          "11 Nov",
          "12 Dec",
        ],
      },
      colors: ["#FF0000"],
      grid: {
        show: false, // Disable background grid lines
      },
    },
  });
  const [dashboradAnalytics, setdashboardAnalytics] = useState({});
  const [dashboardCircleValeues, setdashboardCircleValeues] = useState({});
  const [cookies] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    const getDashboardAnalytics = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = {};

        let response = await invokeApi(
          config.apiDomains + apiList.getDashboard,
          params,
          cookies,
          "get"
        );

        if (response.status >= 200 && response.status < 300) {
          setdashboardAnalytics(response.data.dashboardData);
        } else {
        }
      } else navigate("/");
    };
    const getDashboardGraphValues = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = {};

        let response = await invokeApi(
          config.apiDomains + apiList.getDashboardGraph,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          const rowCountArray = Array.from({ length: 13 }, (_, index) => {
            const monthYear = `${index + 1}-2024`;
            const matchingData = response.data.dashboardData?.find(
              (item) => item.monthYear === monthYear
            );

            return matchingData ? matchingData.rowCount : 0;
          }).slice(1);

          setChartData((prevData) => ({
            ...prevData,
            options: {
              ...prevData.options,
              series: [
                {
                  ...prevData.options.series[0],
                  data: rowCountArray,
                },
              ],
            },
          }));
        } else {
        }
      } else navigate("/");
    };
    const getDashboardPercent = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = {};

        let response = await invokeApi(
          config.apiDomains + apiList.getDashboardPercent,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setdashboardCircleValeues(response.data.dashboardData);
        } else {
        }
      } else navigate("/");
    };

    getDashboardGraphValues();
    getDashboardAnalytics();
    getDashboardPercent();
  }, [cookies, navigate]);

  return (
    <Box sx={{ mt: { md: 5.5, xs: 1 } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              ml: 8,
            }}
          >
            Dashboard
          </Typography>
        </Box>

        <Avatar
          alt={cookies?.vcams_user.name}
          src={cookies?.vcams_user.photo}
          sx={{
            width: 72,
            height: 72,
            ml: 8,
            bgcolor: "red",
            fontSize: "30px",
            letterSpacing: 1,
            display: { md: "flex", xs: "none" },
          }}
        >
          {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
        </Avatar>
      </Box>
      <Box
        container
        sx={{
          display: "flex",
          justifyContent: { md: "space-between", xs: "center" },
          width: "95%",
          m: "auto",
          mt: { md: 10, xs: 5 },
          flexWrap: "wrap",
          alignContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        {
          <>
            <Counts lable={"DCs Today"} count={dashboradAnalytics.todayCount} />
            <Counts
              lable={"DCs Yesterday"}
              count={dashboradAnalytics.yesterdayCount}
            />
            <Counts
              lable={"DCs this Month"}
              count={dashboradAnalytics.monthCount}
            />
            <Counts
              lable={"DCs this Year"}
              count={dashboradAnalytics.yearCount}
              percentage={"85%"}
            />
          </>
        }
      </Box>

      <Grid
        container
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { md: 15, xs: 5 },
        }}
      >
        <Grid item md={10} xs={12}>
          <div className="app">
            <div className="row">
              <div className="mixed-chart">
                <Chart
                  options={chartData.options}
                  series={chartData.options.series}
                  type="area"
                  height={280}
                />
              </div>
            </div>
          </div>
        </Grid>

        <Grid item md={2} xs={12}>
          <Grid
            container
            sx={{ display: "flex", flexDirection: { xs: "row", md: "column" } }}
          >
            <Grid
              item
              xs={5}
              md={12}
              sx={{
                position: "relative",
                ml: { md: "20%", xs: 0 },
              }}
            >
              <Box>
                <CircularChart
                  fillPercentage={100}
                  size={{ md: 70, xs: 45 }}
                  fillColor="black"
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: { md: "18px", xs: "16px" },
                  left: { md: "18px", xs: "16px" },
                }}
              >
                <CircularChart
                  fillPercentage={dashboardCircleValeues?.pendingPercent}
                  size={{ md: 52, xs: 30 }}
                  fillColor="red"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={7}
              sx={{ pr: { md: 0, xs: 1.5 }, pl: { md: 3, xs: 0 } }}
              md={12}
            >
              <Box sx={{ mb: 1 }}>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: 700, letterSpacing: 0.5 }}
                >
                  Total DCs
                </Typography>
                <HorizontalBarChart
                  fillPercentage={100}
                  width={{ md: 10, xs: 8 }}
                  fillColor="black"
                />
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: 700, letterSpacing: 0.5 }}
                >
                  Pending DCs
                </Typography>
                <HorizontalBarChart
                  fillPercentage={dashboardCircleValeues?.pendingPercent}
                  width={{ md: 10, xs: 8 }}
                  fillColor="red"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
