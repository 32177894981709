import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import React, { useState, useRef, useEffect, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../images/vcams-logo.png";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";
import ClearIcon from "@mui/icons-material/Clear";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Chip from "@mui/material/Chip";
import BarcodeScanner from "react-barcode-reader";
import { QrReader } from "react-qr-reader";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { userContext } from "../../useContext/CreateContext";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  editableField: {
    width: 70, // Adjust the width based on your design
  },
}));
const CheckDc = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const componentRef = useRef();
  const [cookies] = useCookies();
  // State to store customer information
  const [dcNumber, setdcNumber] = useState("");
  const [dcCheckedBy, setdcCheckedBy] = useState("");
  const [dcRemarks, setdcRemarks] = useState("");
  const [returnItemDetails, setreturnItemDetails] = useState([]);

  const [dcIssuedBy, setdcIssuedBy] = useState("");
  const [returnCustomerInfo, setreturnCustomerInfo] = useState({});
  const [returnModalOpen, setreturnModalOpen] = useState(false);
  const [returnStatus, setreturnStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");

  const [scanControll, setscanControll] = useState(false);
  const [torchOn, setTorchOn] = useState(false);
  const { scanVisible } = useContext(userContext);

  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    const getDcById = async (id) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        setLoading(true);
        let params = {
          dcId: id,
        };

        // Save Property
        let response = await invokeApi(
          config.apiDomains + apiList.getDCForReturn,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          setreturnCustomerInfo(response.data.dcData.customerInfo);
          setdcNumber(response.data.dcData.dcNumber);
          setdcCheckedBy(response.data.dcData.checkedBy);
          setdcRemarks(response.data.dcData.remarks);
          setreturnItemDetails(response.data.dcData.itemDetails);
          setdcIssuedBy(response.data.dcData.dcIssuedBy);
        } else {
          alert(
            "Something went wrong while saving property data. Please try again later!!"
          );
          setLoading(false);
        }
        setLoading(false);
      } else navigate("/");
    };
    getDcById(id);
  }, [cookies, navigate, id]);

  const handleError = (err) => {
    console.error("Error scanning:", err);
  };

  const renderScanner2 = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Use QR code reader for mobile devices
      return <QrReader onScan={handleScan} onError={handleError} />;
    } else {
      // Use barcode scanner for other devices
      return <BarcodeScanner onScan={handleScan} onError={handleError} />;
    }
  };

  const handleScan = async (data) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      if (data && !isFetching) {
        setscanControll(false);
        setIsFetching(true);
        try {
          // Check if the uniqueId already exists in returnItemDetails
          if (returnItemDetails.some((item) => item.uniqueCode === data)) {
            // Find the item in returnItemDetails array
            const existingItemIndex = returnItemDetails.findIndex(
              (item) => item.uniqueCode === data
            );

            // Check if type is already '#51d851'
            if (
              existingItemIndex !== -1 &&
              returnItemDetails[existingItemIndex].itemStatus === "Returned"
            ) {
              // Display an alert message for scanning the same item twice
              alert("You are scanning the same item Twice!");
            } else {
              // Update the type key with the desired color
              returnItemDetails[existingItemIndex].itemStatus = "Returned";
              returnItemDetails[existingItemIndex].itemDetails?.forEach(
                (item) => {
                  item.colour = "green";
                }
              );

              setreturnItemDetails([...returnItemDetails]); // Update the state with the modified array
            }
          } else {
            setscanControll(false);
            // Add the fetched item detail to the array if uniqueId doesn't exist
          }
        } catch (error) {
          console.error("Error fetching item details:", error);
        } finally {
          setIsFetching(false);
          setscanControll(false);
        }
      }
    } else navigate("/");
  };

  const returnDc = async (dcStatus) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        dcId: id,
        itemDetails: returnItemDetails,
        dcStatus,
        remarks: dcRemarks,
        checkedBy: dcCheckedBy,
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.returnDC,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setsnackMessage("DC returned successfully");
        setsnackOpen(true);
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  useEffect(() => {
    const allReturned = returnItemDetails.every(
      (item) => item.itemStatus === "Returned"
    );
    if (allReturned) {
      setreturnStatus(true);
    } else setreturnStatus(false);
  }, [returnItemDetails]);
  const handleReturnDc = () => {
    // Check if all items have itemStatus === "Returned"
    const allReturned = returnItemDetails.every(
      (item) => item.itemStatus === "Returned"
    );

    if (allReturned) {
      // If all items are returned, set dcStatus to "close" and call returnDC function
      returnDc("Close"); // Call your returnDC function here
    } else {
      // If not all items are returned, show an alert
      setreturnModalOpen(true);
    }
  };

  const renderScanner = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return (
        <Box
          sx={{
            border: "2px solid black",
            borderRadius: "5px",
            overflow: "hidden",
            maxWidth: 280,
            maxHeight: 280,
            m: "auto",
          }}
        >
          <BarcodeScannerComponent
            width={320}
            height={280}
            torch={torchOn}
            facingMode="environment"
            onUpdate={(err, result) => {
              if (result) handleScan(result.text);
            }}
          />
        </Box>
      );
    } else return;
  };

  const consolidateItemDetails = (itemDetails) => {
    const consolidatedMap = new Map();
    itemDetails?.forEach((row) => {
      if (row.itemStatus !== "deleted") {
        const key = `${row.itemName}-${row.type}`;
        if (consolidatedMap.has(key)) {
          const consolidatedRow = consolidatedMap.get(key);

          // Convert quantity to number before adding
          consolidatedRow.quantity += Number(row.quantity);

          // Adding the color based on itemStatus
          const color = row.itemStatus !== "Returned" ? "red" : "green";
          consolidatedRow.serialNumbers.push({
            serialNumber: row.serialNumber,
            color: color,
            uniqueCode: row.uniqueCode,
          });
        } else {
          // Adding the color based on itemStatus for the first item
          const color = row.itemStatus !== "Returned" ? "red" : "green";

          consolidatedMap.set(key, {
            ...row,
            quantity: Number(row.quantity),
            serialNumbers: [
              {
                serialNumber: row.serialNumber,
                color: color,
                uniqueCode: row.uniqueCode,
              },
            ],
          });
        }
      }
    });

    // After pushing serial numbers, update itemStatus based on colors
    const consolidatedItemDetails = Array.from(consolidatedMap.values());

    consolidatedItemDetails?.forEach((item) => {
      const allGreen = item.serialNumbers.every(
        (serial) => serial.color === "green"
      );
      item.itemStatus = allGreen ? "Returned" : "Pending";
    });

    return consolidatedItemDetails;
  };

  const consolidatedItemDetails = consolidateItemDetails(returnItemDetails);

  const handleItemDetailsChip = (clickedItem, itemId) => {
    // Toggle the color between red and green
    const newColour = clickedItem.colour === "red" ? "green" : "red";

    // Create a copy of the returnItemDetails array
    const updatedReturnItemDetails = returnItemDetails.map((item) => {
      // Check if the current item matches the clickedItem
      if (
        item.itemDetails.some(
          (detail) =>
            detail.item === clickedItem.item &&
            item.uniqueCode === clickedItem.uniqueCode
        )
      ) {
        const updatedItemDetails = item.itemDetails.map((detail) =>
          detail.item === clickedItem.item &&
          item.uniqueCode === clickedItem.uniqueCode
            ? { ...detail, colour: newColour }
            : detail
        );

        // Determine the new itemStatus based on the updatedItemDetails
        const newItemStatus = checkIfAllSameColor(updatedItemDetails, "green")
          ? "Returned"
          : "Pending";

        // Update the checkbox status based on the new itemStatus
        const isChecked = newItemStatus === "Returned";

        return {
          ...item,
          itemDetails: updatedItemDetails,
          itemStatus: newItemStatus,
          isChecked: isChecked, // Add the isChecked property
        };
      }
      return item;
    });

    // Update the state to trigger a re-render
    setreturnItemDetails(updatedReturnItemDetails);
  };

  // Helper function to check if all details have the specified color
  const checkIfAllSameColor = (itemDetails, color) => {
    return itemDetails.every((detail) => detail.colour === color);
  };

  const handleCheckboxChange = (id, name) => {
    const existingItemIndex = consolidatedItemDetails.findIndex(
      (item) => item.itemId === id
    );

    if (existingItemIndex !== -1) {
      const currentItem = consolidatedItemDetails[existingItemIndex];

      if (currentItem.type === "Single") {
        const matchingReturnItem = returnItemDetails.find(
          (item) => item.uniqueCode === currentItem.uniqueCode
        );

        if (matchingReturnItem) {
          matchingReturnItem.itemStatus =
            matchingReturnItem.itemStatus === "Pending"
              ? "Returned"
              : "Pending";

          matchingReturnItem.itemDetails?.forEach((item) => {
            item.colour =
              matchingReturnItem.itemStatus === "Returned" ? "green" : "red";
          });

          setreturnItemDetails([...returnItemDetails]);
        }
      } else if (currentItem.type === "Multiple") {
        // For Multiple items
        const multipleItemUniqueCodes = currentItem.serialNumbers.map(
          (item) => item.uniqueCode
        );

        returnItemDetails?.forEach((returnItem) => {
          if (
            returnItem.type === "Multiple" &&
            multipleItemUniqueCodes.includes(returnItem.uniqueCode)
          ) {
            // Toggle itemStatus only if it is currently "Pending"
            if (returnItem.itemStatus === "Pending") {
              returnItem.itemStatus = "Returned";

              currentItem.serialNumbers.forEach((serialNumber) => {
                if (serialNumber.uniqueCode === returnItem.uniqueCode) {
                  // Set color to "green" when changing to "Returned"
                  serialNumber.color = "green";
                }
              });
            }
            // Add condition to check if it's already "Returned," then don't change it
            else if (returnItem.itemStatus === "Returned") {
              // Do nothing
            } else {
              // If checkbox is unchecked, set itemStatus back to "Pending" and color to "red"
              returnItem.itemStatus = "Pending";

              currentItem.serialNumbers.forEach((serialNumber) => {
                if (serialNumber.uniqueCode === returnItem.uniqueCode) {
                  // Set color back to "red"
                  serialNumber.color = "red";
                }
              });
            }
          }
        });

        setreturnItemDetails([...returnItemDetails]);
      }

      consolidateItemDetails(returnItemDetails);
    }
  };

  const handleRemarkChange = (id, e) => {
    const existingItemIndex = consolidatedItemDetails.findIndex(
      (item) => item.itemId === id
    );
    if (existingItemIndex !== -1) {
      const currentItem = consolidatedItemDetails[existingItemIndex];
      const matchingReturnItem = returnItemDetails.find(
        (item) => item.uniqueCode === currentItem.uniqueCode
      );

      if (matchingReturnItem) {
        matchingReturnItem.itemRemarks = e.target.value;
      }
      setreturnItemDetails([...returnItemDetails]);
    }
    consolidateItemDetails(returnItemDetails);
  };

  return (
    <Box>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "DC Deleted successfully" ? "red" : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
      <Dialog
        open={returnModalOpen}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 700,
            minWidth: { md: 700, xs: 250 },
          },
        }}
      >
        <DialogContent id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              p: { md: 5, xs: 0 },
            }}
          >
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
                textAlign: "center",
              }}
            >
              A few Items are still pending.
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
                textAlign: "center",
              }}
            >
              Are you sure you want to close DC?
            </Box>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              setreturnModalOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#ff0000d4",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              returnDc("Open");
              setreturnModalOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#008000bd",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "green",
                color: "white",
              },
            }}
          >
            Return DC
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          fontSize: "18px",
          letterSpacing: 1,
          fontWeight: 500,
          color: "#433e3e",
          mt: 1,
          mr: 4,
          mb: -3,
        }}
      >
        Returning the ITEMS
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          mt: 1,
          mr: 4,
          mb: -1,
        }}
      >
        <ClearIcon
          onClick={() => navigate("/v-cams")}
          sx={{
            cursor: "pointer",
            mt: -0.8,
            width: "48px",
            "&:hover": { backgroundColor: "#8A8A8A63" },
          }}
        />
      </Box>
      <Box>
        <Paper
          className={classes.root}
          ref={componentRef}
          style={{ padding: 25, boxShadow: "none" }}
        >
          <Box sx={{ border: "1px solid #000" }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #000",
                paddingBottom: "5px",
                pl: 2,
                mb: -2,
              }}
            >
              <Box sx={{ width: "30%", borderRight: "1px solid #000" }}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",
                    fontSize: "14px",
                    pt: 0.6,
                  }}
                >
                  DC Issued By: {dcIssuedBy}
                </Box>
                <Box
                  sx={{
                    pr: 2,
                    maxHeight: 200,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "black",
                      display: "flex",
                      mt: 1,
                      objectFit: "fit",
                      width: "75%",
                      height: 75,
                    }}
                    component="img"
                    src={logo}
                  />
                </Box>
                <Box sx={{ ml: -2 }}>
                  <Typography style={{ textAlign: "center", fontSize: "13px" }}>
                    <b>No.3, 2nd cross Rd, 2nd main Rd, Kanakpura Rd,</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "13px" }}>
                    <b>Vivekananda colony, 1st phase, J.P Nagar,</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "13px" }}>
                    <b>Bengaluru, Karnataka 560078</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "13px" }}>
                    <b>Website : www.vcams.in</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "13px" }}>
                    <b>PH NO : 9901999694, 9901999697</b>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "70%" }}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>DC Number:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {dcNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>Client Name:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>Phone Number:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.mobileNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>Pack Time:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.packTime}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>Date Out:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.dateOut}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b> Time Out:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.timeOut}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>Shoot Date:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.shootDate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b> Delevered By:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.deliveredBy}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b>Collected By:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.collectedBy}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                  >
                    <b> Crew:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "13.5px",
                      marginLeft: "5px",
                    }}
                  >
                    {returnCustomerInfo.crew}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* ... Your existing header code ... */}
            {/* Table */}

            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              // style={{paddingLeft:2}}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} style={{ padding: "0px" }}>
                      <span
                        style={{
                          fontWeight: 550,
                          whiteSpace: "nowrap",
                          fontSize: "14px",
                          paddingLeft: "5px",
                        }}
                      >
                        DC Number:
                        <span style={{ marginLeft: "8px" }}>{dcNumber}</span>
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      borderBottom: "none",
                      borderTop: "none",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <TableCell
                      style={{
                        minWidth: 10,
                        maxWidth: 10,
                        whiteSpace: "nowrap",
                        border: "none",
                        padding: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <b> Sl. no</b>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 500,
                        maxWidth: 500,
                        border: "none",
                        padding: "5px",
                        paddingLeft: "30px",
                        fontSize: "14px",
                      }}
                    >
                      <b>Item Details</b>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 500,
                        maxWidth: 500,
                        border: "none",
                        padding: "5px",
                        fontSize: "14px",
                        paddingRight: "10px",
                      }}
                    >
                      <b>Remarks</b>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 120,
                        maxWidth: 120,
                        border: "none",
                        padding: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <b>Status</b>
                    </TableCell>
                    {cookies.vcams_user.role === "admin" && (
                      <TableCell
                        style={{
                          minWidth: 50,
                          maxWidth: 50,
                          border: "none",
                          padding: "5px",
                          fontSize: "14px",
                          paddingRight: "30px",
                        }}
                      >
                        {!returnStatus && <b>Return Manually</b>}
                      </TableCell>
                    )}
                    <TableCell
                      style={{
                        minWidth: 10,
                        maxWidth: 10,
                        border: "none",
                        padding: "5px",
                        fontSize: "14px",
                        paddingRight: "10px",
                      }}
                    >
                      <b>Qty</b>
                    </TableCell>
                    {/* <TableCell
                      style={{
                        width: "2%",
                        border: "none",
                        padding: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <b>Status</b>
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {consolidatedItemDetails?.map((consolidatedRow, index) => (
                    <TableRow
                      key={index}
                      style={{
                        pageBreakInside: "avoid",
                        border: "1px solid #000",
                        borderRight: "none",
                      }}
                    >
                      <TableCell
                        style={{
                          minWidth: 10,
                          maxWidth: 10,
                          border: "none",
                          padding: "5px",
                          fontSize: "12.5px",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: 500,
                          maxWidth: 500,
                          display: "flex",
                          flexDirection: "column",
                          border: "none",
                          padding: "5px",
                          marginLeft: "25px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection:
                              consolidatedRow.type === "Single"
                                ? "row"
                                : "column",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Box sx={{ fontSize: "11px" }}>
                            {/* <span style={{ marginRight: "8px" }}>•</span> */}
                            <span>{consolidatedRow.itemName}</span>
                          </Box>
                          {consolidatedRow.type === "Single" && (
                            <Box sx={{ ml: 1 }}>-</Box>
                          )}
                          {consolidatedRow.type === "Multiple" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {consolidatedRow.serialNumbers.map(
                                (item) =>
                                  item.item !== "" && (
                                    <Box
                                      // name="subCategory"
                                      // value={consolidatedRow.returnItemDetails}
                                      // onChange={(e) => handleInputChange(e, index)}
                                      // className={classes.editableField}
                                      sx={{
                                        paddingLeft: "5px",
                                        fontSize: "12.5px",
                                        display: "flex",
                                        flexDirection: "row",
                                        mb: 0.5,
                                        mt: 0.5,
                                      }}
                                    >
                                      <Chip
                                        label={item.serialNumber}
                                        sx={{
                                          height: "20px",
                                          backgroundColor: item.color,
                                          color: "white",
                                        }}
                                      />
                                    </Box>
                                  )
                              )}
                            </Box>
                          )}
                          {consolidatedRow.type === "Single" && (
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              {consolidatedRow.serialNumbers.map(
                                (item) =>
                                  item.item !== "" && (
                                    <Box sx={{ fontSize: "10px" }}>
                                      <span style={{ marginLeft: "10px" }}>
                                        ({item.serialNumber})
                                      </span>
                                    </Box>
                                  )
                              )}
                            </Box>
                          )}
                        </div>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {consolidatedRow.itemDetails.map(
                            (item) =>
                              item.item !== "" && (
                                <Box
                                  // name="subCategory"
                                  // value={consolidatedRow.returnItemDetails}
                                  // onChange={(e) => handleInputChange(e, index)}
                                  // className={classes.editableField}
                                  sx={{
                                    paddingLeft: "5px",
                                    fontSize: "12.5px",
                                    display: "flex",
                                    flexDirection: "row",
                                    cursor: "pointer",
                                    mt: 0.5,
                                    mb: 0.5,
                                  }}
                                  onClick={() =>
                                    handleItemDetailsChip(
                                      item,
                                      consolidatedRow.itemId
                                    )
                                  }
                                >
                                  <Chip
                                    label={item.item}
                                    sx={{
                                      height: "20px",
                                      backgroundColor: item.colour,
                                      color: "white",
                                    }}
                                  />
                                </Box>
                              )
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          padding: "5px",
                          fontSize: "12.5px",
                          minWidth: 500,
                          maxWidth: 500,
                        }}
                      >
                        <textarea
                          style={{
                            border: "none",
                            outline: "none",
                            minWidth: "90%",
                            maxWidth: "90%",
                            minHeight: "30px",
                          }}
                          onChange={(e) =>
                            handleRemarkChange(consolidatedRow.itemId, e)
                          }
                          value={consolidatedRow.itemRemarks}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          padding: "5px",
                          fontSize: "12.5px",
                          minWidth: 120,
                          maxWidth: 120,
                        }}
                      >
                        {consolidatedRow.type !== "Multiple" && (
                          <Box
                            sx={{
                              marginRight: "30px",
                              marginLeft: "-5px",
                              color: "white",
                              backgroundColor:
                                consolidatedRow.itemStatus === "Pending"
                                  ? "red"
                                  : "green",
                              borderRadius: "20px 20px 20px 20px",
                              maxHeight: "30px",
                              minHeight: "30px",
                              fontSize: "13px",
                              minWidth: "100px",
                              textAlign: "center",
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {consolidatedRow.itemStatus}
                            {consolidatedRow.itemStatus === "Pending" && (
                              <span>...</span>
                            )}
                          </Box>
                        )}
                        {consolidatedRow.type === "Multiple" && (
                          <Box
                            sx={{
                              marginRight: "30px",
                              marginLeft: "-5px",
                              color: "white",
                              backgroundColor:
                                consolidatedRow.itemStatus === "Pending"
                                  ? "red"
                                  : "green",
                              borderRadius: "20px 20px 20px 20px",
                              maxHeight: "30px",
                              minHeight: "30px",
                              fontSize: "13px",
                              minWidth: "100px",
                              textAlign: "center",
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {consolidatedRow.itemStatus}
                            {consolidatedRow.itemStatus === "Pending" && (
                              <span>...</span>
                            )}
                          </Box>
                        )}
                      </TableCell>
                      {cookies.vcams_user.role === "admin" && (
                        <TableCell
                          style={{
                            border: "none",
                            padding: "5px",
                            fontSize: "12.5px",
                            minWidth: 50,
                            maxWidth: 50,
                            paddingLeft: "15px",
                          }}
                        >
                          {consolidatedRow.itemStatus === "Pending" && (
                            <Box>
                              <input
                                type="checkbox"
                                checked={consolidatedRow.isChecked}
                                onChange={() =>
                                  handleCheckboxChange(
                                    consolidatedRow.itemId,
                                    consolidatedRow.itemName
                                  )
                                }
                              />
                            </Box>
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          border: "none",
                          padding: "5px",
                          fontSize: "12.5px",
                          minWidth: 10,
                          maxWidth: 10,
                        }}
                      >
                        <Box sx={{ mr: 3 }}>{consolidatedRow.quantity}</Box>
                      </TableCell>
                      {/* <TableCell
                        style={{
                          border: "none",
                          padding: "5px",
                          fontSize: "12.5px",
                        }}
                      >
                        <CheckIcon sx={{color:'green', width:'20px'}}/>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ padding: "0px", borderTop: "1px solid #00000042" }}
                  >
                    <span
                      style={{
                        fontWeight: 550,
                        whiteSpace: "nowrap",
                        fontSize: "11px",
                        paddingLeft: "5px",
                        marginRight: "30px",
                      }}
                    >
                      Checked by:
                      <input
                        name="mobileNumber"
                        value={dcCheckedBy}
                        style={{
                          outline: "none",
                          marginLeft: "5px",
                          width: "200px",
                          border: "none",
                          fontSize: "10px",
                          marginTop: "-2px",
                        }}
                        onChange={(e) => setdcCheckedBy(e.target.value)}
                      />
                    </span>
                    <span
                      style={{
                        fontWeight: 550,
                        whiteSpace: "nowrap",
                        fontSize: "11px",
                        paddingLeft: "5px",
                      }}
                    >
                      Remarks:
                      <input
                        name="mobileNumber"
                        value={dcRemarks}
                        style={{
                          outline: "none",
                          marginLeft: "5px",
                          width: "400px",
                          border: "none",
                          fontSize: "10px",
                          marginTop: "-2px",
                        }}
                        onChange={(e) => setdcRemarks(e.target.value)}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Box>
          {/* Header */}
        </Paper>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          mb: 5,
        }}
      >
        {scanControll && (
          <>
            {renderScanner()}
            <Button onClick={() => setTorchOn((prev) => !prev)}>
              {torchOn ? <FlashlightOffIcon /> : <FlashlightOnIcon />}
            </Button>
          </>
        )}
      </Box>
      {!scanControll && (
        <Box>
          <Button
            sx={{
              display: { md: "none", xs: "flex" },
              borderRadius: "10px",
              maxHeight: "29.5px",
              pl: { md: 2, xs: 1 },
              pr: { md: 2, xs: 1 },
              textTransform: "none",
              whiteSpace: "nowrap",
              maxWidth: "120",
              minWidth: { md: "120", xs: "100px" },
              background: "#ff5400d4",
              color: "black",
              "&:hover": {
                background: "#ff5400d4",
                color: "white",
              },
              m: "auto",
              mb: 5,
            }}
            onClick={() => setscanControll(true)}
          >
            Scan
          </Button>
        </Box>
      )}

      <Box sx={{ mb: 5, display: "flex", justifyContent: "right", mr: 4 }}>
        <Button
          sx={{
            borderRadius: "10px",
            height: "30px",
            pl: { md: 3, xs: 1 },
            pr: { md: 3, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            background: "#2ece2e",
            color: "black",
            "&:hover": {
              background: "#2ece2e",
              color: "white",
            },
          }}
          onClick={handleReturnDc}
        >
          Return DC
        </Button>
      </Box>
      {scanVisible && (
        <Box sx={{ display: { md: "flex", xs: "none" } }}>
          {renderScanner2()}
        </Box>
      )}
    </Box>
  );
};

export default CheckDc;
