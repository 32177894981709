export const config = {
    apiDomains: "https://vcams.unknownaid.in",
    // apiDomains: "https://seerpulab.unknownaid.in",
   
   
    cookieName: "vcams_user",
    sessionCookie: "vcams_sess",
    userPicCookie: "vcams_loggedin_user_pic",
    userNameCookie: "vcams_loggedin_user_name",
    preferencesCookie: "vcams_pref",
  }
  
  
  
  