import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


import ClearIcon from "@mui/icons-material/Clear";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";

const RecycleInventory = () => {
  const { name } = useParams();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  useEffect(() => {
    const getDeletedProducts = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        setLoading(true);
        let params = {};

        let response = await invokeApi(
          config.apiDomains + apiList.getDeletedProducts,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          setTableData(response.data.itemData);
        } else if (response.status >= 400 && response.status < 510) {
          alert(
            `Products with name of ${name} are not available, please go one step back and search for other PRODUCT NAME`
          );
          setLoading(false);
        } else {
          alert(
            "Something went wrong while saving property data. Please try again later!!"
          );
          setLoading(false);
        }
        setLoading(false);
      } else navigate("/");
    };
    getDeletedProducts();
  }, [name, cookies,  navigate]);

 
  const revertDeleteProduct = async (itemId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { itemId };

      let response = await invokeApi(
        config.apiDomains + apiList.revertDeleteProduct,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        const updatedTableData = tableData.filter(
          (row) => row.itemId !== itemId
        );

        // Update the state to re-render the component without the deleted row
        setTableData(updatedTableData);
        setsnackOpen(true);
        setsnackMessage("Product restored successfully");
      } else {
        alert(
          "Something went wrong while saving property data. Please try again later!!"
        );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  

  return (
    <Box sx={{ mt: { md: 4, xs: 1, minHeight: "95vh" } }}>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "Product deleted successfully"
                  ? "red"
                  : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box
          onClick={() => {
            navigate("/v-cams");
          }}
          sx={{
            mr: { md: 4, xs: 1 },
            ml: "auto",
            cursor: "pointer",
            "&:hover": { backgroundColor: "#8A8A8A63" },
          }}
        >
          <ClearIcon sx={{ color: "red", height: "40px", width: "40px" }} />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        {/* Header */}
        <Table style={{ display: "block" }}>
          <TableHead>
            <TableRow style={{ pageBreakInside: "avoid" }}>
              <TableCell style={{ minWidth: 50 }}>Sl. no</TableCell>
              <TableCell style={{ minWidth: 300 }}>Item Name</TableCell>
              <TableCell style={{ minWidth: 200 }}>Category</TableCell>
              <TableCell style={{ minWidth: 200 }}>Sub-category</TableCell>
              <TableCell style={{ minWidth: 200 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
        </Table>

        {/* Body - Scrollable */}
        <Table
          style={{
            borderCollapse: "separate",
            // overflowY: "auto",
            display: "block",
            // maxHeight: 400,
          }}
        >
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} style={{ pageBreakInside: "avoid" }}>
                <TableCell style={{ minWidth: 50 }}>{index + 1}</TableCell>
                <TableCell style={{ minWidth: 300 }}>{row.itemName}</TableCell>
                <TableCell style={{ minWidth: 200 }}>{row.category}</TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  {row.subCategory}
                </TableCell>

                <TableCell style={{ minWidth: 200 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                    }}
                  >
                    <Button
                      onClick={() => revertDeleteProduct(row.itemId)}
                      sx={{
                        borderRadius: "10px",
                        background: "#007cff",
                        color: "black",
                        height: "32px",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        minWidth: "50px",
                        "&:hover": {
                          background: "#007cff",
                          color: "white",
                        },
                      }}
                    >
                      Restore
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecycleInventory;
