import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const Category = () => {
  const [cookies] = useCookies();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [category, setCategory] = useState();
  const [categoryId, setCategoryId] = useState();
  const [tableData, setTableData] = useState([]);
  const [categoryUpdated, setCategoryUpdated] = useState(false);
  const [handleUpdateCategory, setHandleUpdateCategory] = useState(false);
  const [items, setItems] = useState([{ value: "", subcategoryId: null }]);
  const [snackMessage, setsnackMessage] = useState();
  const navigate = useNavigate();
  const [deletedSubcategoryIds, setDeletedSubcategoryIds] = useState([]);

  const handleInputChange = (index, value) => {
    const newItems = [...items];
    newItems[index].value = value;
    setItems(newItems);
  };

  const handleAddMore = () => {
    setItems([...items, { value: "", subcategoryId: null }]);
  };

  const handleDelete = (index) => {
    if (index > 0) {
      const newItems = [...items];
      // newItems.splice(index, 1);
      // setItems(newItems);
      if (newItems[index].subcategoryId) {
        setDeletedSubcategoryIds([...deletedSubcategoryIds, newItems[index].subcategoryId]);
      }
      newItems.splice(index, 1);
      setItems(newItems);
    } else {
      return;
    }
  };

  const addCategory = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        category: category,
        subcategory: items.map(item => ({
          subcategoryId: item.subcategoryId, // This will be null for new items
          subcategoryName: item.value
        })),
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.addCategory,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setsnackOpen(true);
        setsnackMessage("Category saved successfully");
        setOpen(false);
        setCategoryUpdated(true);
        setItems([""]);
      } else {
        // alert("Something went wrong while saving. Please try again later!!");
        setItems([""]);
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const updateCategory = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        categoryId,
        category,
        subcategory: items.filter(item => item.subcategoryId || item.value).map(item => ({
          subcategoryId: item.subcategoryId,
          subcategoryName: item.value
        })),
        deletedSubcategoryIds: deletedSubcategoryIds,
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.updateCategory,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setCategoryUpdated(true);
        setsnackOpen(true);
        setsnackMessage("Category Updated successfully");
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
      // setActiveStep(0);
    } else navigate("/");
  };

  const getCategory = async (categoryId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        categoryId,
      };

      let response = await invokeApi(
        config.apiDomains + apiList.getCategory,
        params,
        cookies,
        "get"
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setCategory(response.data.categoryData.category);
        setCategoryId(response.data.categoryData.categoryId);
        if (response.data.categoryData.subcategory) {
          const loadedItems = response.data.categoryData.subcategory.map(subcat => ({
            subcategoryId: subcat.subcategoryId,
            value: subcat.subcategoryName
          }));
          setItems(loadedItems);
          setDeletedSubcategoryIds([]);
        } else {
          setItems([""]);
        }
        setOpen(true);
        setHandleUpdateCategory(true);
      } else {
        // alert("Something went wrong while fetching data. Please try again!!");
        setLoading(false);
      }
      setLoading(false);
      // setActiveStep(0);
    } else navigate("/");
  };

  const deleteCategory = async (categoryId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { categoryId };

      let response = await invokeApi(
        config.apiDomains + apiList.deleteCategory,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setCategoryUpdated(true);
        setsnackOpen(true);
        setsnackMessage("Category Deleted successfully");
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
      // setActiveStep(0);
    } else navigate("/");
  };

  useEffect(() => {
    const getCategories = async () => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        setLoading(true);
        let params = {};

        let response = await invokeApi(
          config.apiDomains + apiList.getCategories,
          params,
          cookies,
          "get"
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          setTableData(response.data.categoryData);
          setCategoryUpdated(false);
        } else {
          // alert("Not able to get data. Check your APIs.");
          setLoading(false);
        }
        setLoading(false);
      } else navigate("/");
    };

    getCategories();
  }, [cookies, categoryUpdated, navigate]);

  const handleAddNewCategory = () => {
    setOpen(true);
    setHandleUpdateCategory(false);
    setCategory("");
    setItems([{ value: "", subcategoryId: null }]);
  };

  return (
    <Box sx={{ mt: { md: 5.5, xs: 1, minHeight: "100vh" } }}>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "Category Deleted successfully"
                  ? "red"
                  : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              ml: 8,
            }}
          >
            Category
          </Typography>
        </Box>

        <Avatar
          alt={cookies?.vcams_user.name}
          src={cookies?.vcams_user.photo}
          sx={{
            width: 72,
            height: 72,
            ml: 8,
            bgcolor: "red",
            fontSize: "30px",
            letterSpacing: 1,
            display: { md: "flex", xs: "none" },
          }}
        >
          {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
        </Avatar>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { md: "96%", xs: "98%" },
          m: "auto",
        }}
      >
        <Button
          sx={{
            borderRadius: "10px",
            height: "30px",
            pl: { md: 2, xs: 1 },
            pr: { md: 2, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            maxWidth: "180px",
            minWidth: "180px",
            background: "#ff0000d4",
            color: "black",
            "&:hover": {
              background: "red",
              color: "white",
            },
          }}
          onClick={handleAddNewCategory}
        >
          New Category +
        </Button>
      </Box>
      <Dialog
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 700,
            minWidth: { md: 700, xs: 250 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              Add New Category
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              <ClearIcon
                onClick={() => setOpen(false)}
                sx={{
                  cursor: "pointer",
                  mt: 0.8,
                  "&:hover": { backgroundColor: "#8A8A8A63" },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 0,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={(e) => setCategory(e.target.value)}
                value={category}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "black",
                    textAlign: "center",
                    letterSpacing: "8px",
                  },
                  "& .MuiInputBase-input": {
                    backgroundColor: "#8A8A8A63",
                    height: ".01em",
                    minWidth: { md: 330, xs: 0 },
                  },
                  mb: 2,
                  mr: 1,
                  ml: 1,
                }}
                placeholder="Category"
              />
            </Box>
          </DialogContentText>
          <Box
            sx={{
              m: "auto",
              mt: 1,
            }}
          >
            <Box
              sx={{
                color: "#000000bf",
                letterSpacing: 1,
                fontSize: "17px",
                mb: 1,
              }}
            >
              Please provide the Subcategories:
            </Box>
            <div style={{}}>
              {items.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 0.5,
                    marginTop: index > 0 ? 2 : 0,
                  }}
                >
                  <input
                    style={{
                      border: "none",
                      // borderRadius: "10px",
                      background: "#8A8A8A63",
                      paddingLeft: "10px",
                      letterSpacing: 1,
                      paddingTop: "8px",
                      width: "100%",
                      paddingBottom: "10px",
                      outline: "none",
                    }}
                    placeholder="Subcategory"
                    value={item.value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                  <input
                    style={{
                      border: "none",
                      // borderRadius: "10px",
                      background: "#8A8A8A63",
                      paddingLeft: "10px",
                      letterSpacing: 1,
                      paddingTop: "8px",
                      width: "100%",
                      paddingBottom: "10px",
                      outline: "none",
                      display: "none",
                    }}
                    value={item.subcategoryId}
                  />

                  <DeleteIcon
                    onClick={() => handleDelete(index)}
                    sx={{ cursor: "pointer", color: "#ff1010e3", mt: 0.8 }}
                  />
                </Box>
              ))}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <Button
                  sx={{
                    borderRadius: "10px",
                    background: "#8A8A8A63",
                    color: "black",
                    height: { md: "32px", xs: "25px" },
                    pl: { md: 2, xs: 1 },
                    pr: { md: 2, xs: 1 },
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    minWidth: { md: "150px", xs: "50px" },
                  }}
                  onClick={handleAddMore}
                >
                  Add More +
                </Button>
              </Box>
            </div>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#ff0000d4",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              if (handleUpdateCategory) {
                setOpen(false);
                updateCategory();
              } else if (!handleUpdateCategory) {
                setOpen(false);
                addCategory();
              }
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#008000bd",
              color: "black",
              height: "32px",
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "green",
                color: "white",
              },
            }}
          >
            Add Category
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: { md: "96%", xs: "96%" },
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          m: "auto",
          mt: 2,
          mb: 8,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",
            mt: 2,
            mb: 2,
            ml: 2,

            "& .MuiDataGrid-withBorderColor": {
              border: "none !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root": {
              color: "black !important",
              display: "none !important",
            },

            "& .css-wop1k0-MuiDataGrid-footerContainer": {
              display: "none !important",
            },
            "& .css-j204z7-MuiFormControlLabel-root": {
              display: "none !important",
            },

            "& .css-204u17-MuiDataGrid-main ": {
              pl: "20px !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root ": {
              pl: "25px !important",
            },
            "& .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:hover, .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered":
              {
                backgroundColor: "white !important",
              },
          }}
          aria-expanded="true"
        >
          <Box sx={{ width: 0.99 }}>
            <Box>
              <TableContainer component={Paper}>
                {/* Header */}
                <Table style={{ display: "block" }}>
                  <TableHead>
                    <TableRow style={{ pageBreakInside: "avoid" }}>
                      <TableCell style={{ minWidth: 80 }}>Sl. no</TableCell>
                      <TableCell style={{ minWidth: 400 }}>Category</TableCell>
                      <TableCell style={{ minWidth: 300 }}>
                        Sub-Category
                      </TableCell>
                      <TableCell style={{ minWidth: 200 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>

                {/* Body - Scrollable */}
                <Table
                  style={{
                    borderCollapse: "separate",
                    // overflowY: "auto",
                    display: "block",
                    // maxHeight: 400,
                  }}
                >
                  <TableBody>
                    {tableData.map((row, index) =>
                      // Check if the status is 'inactive'
                      row.status === "Inactive" ? null : (
                        <TableRow
                          key={index}
                          style={{ pageBreakInside: "avoid" }}
                        >
                          <TableCell style={{ minWidth: 80 }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ minWidth: 400 }}>
                            {row.category}
                          </TableCell>
                          <TableCell style={{ minWidth: 300 }}>
                            {row.subcategory?.split(",").map((sub) => (
                              <ul style={{ marginTop: -10 }}>
                                <li style={{ marginBottom: -15 }}>{sub}</li>
                              </ul>
                            ))}
                          </TableCell>
                          <TableCell style={{ minWidth: 200 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 1,
                              }}
                            >
                              <Button
                                onClick={() => getCategory(row.categoryId)}
                                sx={{
                                  borderRadius: "10px",
                                  background: "#008000bd",
                                  color: "black",
                                  height: "32px",
                                  textTransform: "none",
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                  "&:hover": {
                                    background: "green",
                                    color: "white",
                                  },
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "10px",
                                  background: "red",
                                  color: "black",
                                  height: "32px",
                                  textTransform: "none",
                                  whiteSpace: "nowrap",
                                  minWidth: "50px",
                                  "&:hover": {
                                    background: "red",
                                    color: "white",
                                  },
                                }}
                                onClick={() => deleteCategory(row.categoryId)}
                              >
                                Delete
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        color="primary" aria-expanded="true"
        // startIcon={<SaveAltIcon />}
        onClick={handleExportExcelClick}
      >
        Export as Excel
      </Button> */}
    </Box>
  );
};

export default Category;
