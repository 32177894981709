import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Paper,
  Snackbar,
  SnackbarContent,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import BillingPage from "./CreateDc";
import { userContext } from "../../useContext/CreateContext";
import { DialogTitle } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Dc = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [customer, setcustomer] = useState("");
  const [dcNum, setdcNum] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dcItem, setdcItem] = useState("");
  const [functionCall, setfunctionCall] = useState("");

  const [value, setValue] = React.useState(0);
  const numberArray = Array.from(
    { length: pageCount },
    (_, index) => index + 1
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    
    itemDetails,
    customerInfo,
    dcCheckedBy,
    remarks,
    setItemDetails,
    setCustomerInfo,
    setremarks,
    setdcOpeningType,
    setdcIssuedBy,
    dcOpeningType,
    setdcNumber,
    setdcCreator,
    dcId,
    setdcId,
    dcIssuedBy,
    setdcCheckedBy,
  } = useContext(userContext);

  const [dcList, setdcList] = useState([]);

  const saveDc = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        itemDetails,
        customerInfo,
        checkedBy: dcCheckedBy,
        remarks: remarks,
        dcIssuedBy,
        dcItemId: "",
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.addDC,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setdcNumber(response.data.dcData.dcNumber);
        setsnackMessage("DC saved successfully");
        setsnackOpen(true);
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const updateDC = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        itemDetails,
        customerInfo,
        checkedBy: dcCheckedBy,
        remarks: remarks,
        dcId,
        dcIssuedBy,
      };

      // Save Property
      let response = await invokeApi(
        config.apiDomains + apiList.updateDC,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setsnackMessage("DC Updated successfully");
        setsnackOpen(true);
        setCustomerInfo({
          name: "",
          customerId: "",
          mobileNumber: "",
          packTime: "",
          dateOut: "",
          timeOut: "",
          shootDate: "",
          deliveredBy: "",
          collectedBy: "",
          crew: "",
        });
        setdcNumber("");
        setdcCreator("");
        setdcCheckedBy("");
        setremarks("");
        setItemDetails([]);
        setdcIssuedBy("");
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };
  const getDcToUpdate = async (dcId) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = { dcId };

      let response = await invokeApi(
        config.apiDomains + apiList.getDC,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setCustomerInfo(response.data.dcData.customerInfo);
        setdcNumber(response.data.dcData.dcNumber);
        setdcCreator(response.data.dcData.createdName);
        setdcCheckedBy(response.data.dcData.checkedBy);
        setremarks(response.data.dcData.remarks);
        setItemDetails(response.data.dcData.itemDetails);
        setdcId(response.data.dcData.dcId);
        setdcOpeningType("UPDATEDC");
        setOpen(true);
        // setsnackMessage("DC updated successfully");setsnackOpen(true)
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  useEffect(() => {
    const getDcList = async () => {
      if (
        functionCall !== "DCBYDATE" &&
        functionCall !== "DCBYCUSTOMER" &&
        functionCall !== "DCBYNUMBER" &&
        functionCall !== "DCBYITEM"
      ) {
        if (new Date(cookies.vcams_sess.expiry) > new Date()) {
          setLoading(true);
          let params = { offset: value + 1 };

          // Save Property
          let response = await invokeApi(
            config.apiDomains + apiList.getDCs,
            params,
            cookies
          );

          if (response.status >= 200 && response.status < 300) {
            setLoading(false);
            setdcList(response.data.dcData);
            setpageCount(response.data.pageCount);
            setpageCount(response.data.pageCount);
          } else if (response.status >= 300 || response.status < 500) {
            // alert("No data found.. Add some data first!!");
            setLoading(false);
          }
          setLoading(false);
        } else navigate("/");
      }
    };

    getDcList();
  }, [cookies, navigate, snackMessage, functionCall, value]);

  useEffect(() => {
    const fetchNameSuggestions = async (input) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = { customerName: "" };

        let response = await invokeApi(
          config.apiDomains + apiList.getCustomerByName,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setNameSuggestions(response.data.customerData);
        }
      } else navigate("/");
    };
    fetchNameSuggestions();
  }, [cookies, navigate]);

  useEffect(() => {
    const getDcByDate = async () => {
      if (functionCall === "DCBYDATE") {
        if (new Date(cookies.vcams_sess.expiry) > new Date()) {
          let params = { startDate, endDate, offset: value + 1 };

          if ((startDate !== "") & (endDate !== "") && startDate < endDate) {
            let response = await invokeApi(
              config.apiDomains + apiList.getDcByDate,
              params,
              cookies
            );

            if (response.status >= 200 && response.status < 300) {
              setdcList([]);
              setdcList(response.data.dcData);
              setpageCount(response.data.pageCount);
            }
          }
        } else navigate("/");
      }
    };
    getDcByDate();
  }, [cookies, navigate, startDate, functionCall, endDate, value]);

  useEffect(() => {
    const getDcByCustomer = async () => {
      if (functionCall === "DCBYCUSTOMER") {
        if (new Date(cookies.vcams_sess.expiry) > new Date()) {
          let params = { customerName: customer, offset: value + 1 };

          if (customer !== "") {
            let response = await invokeApi(
              config.apiDomains + apiList.getDcByCustomer,
              params,
              cookies
            );

            if (response.status >= 200 && response.status < 300) {
              setdcList([]);
              setdcList(response.data.dcData);
              setpageCount(response.data.pageCount);
            }
          }
        } else navigate("/");
      }
    };
    getDcByCustomer();
  }, [cookies, navigate, value, customer, functionCall]);

  useEffect(() => {
    const getDcByItems = async () => {
      if (functionCall === "DCBYITEM") {
        if (new Date(cookies.vcams_sess.expiry) > new Date()) {
          let params = { itemName: dcItem, offset: value + 1 };

          if (dcItem !== "") {
            let response = await invokeApi(
              config.apiDomains + apiList.getDcByItems,
              params,
              cookies
            );

            if (response.status >= 200 && response.status < 300) {
              setdcList([]);
              setdcList(response.data.dcData);
              setpageCount(response.data.pageCount);
            }
          }
        } else navigate("/");
      }
    };
    getDcByItems();
  }, [cookies, navigate, value, functionCall, dcItem]);

  useEffect(() => {
    const getDCbyDcNumber = async () => {
      if (functionCall === "DCBYNUMBER") {
        if (new Date(cookies.vcams_sess.expiry) > new Date()) {
          let params = { dcNumber: dcNum, offset: value + 1 };

          if (dcNum !== "") {
            let response = await invokeApi(
              config.apiDomains + apiList.getDCbyDcNumber,
              params,
              cookies
            );

            if (response.status >= 200 && response.status < 300) {
              setdcList([]);
              setdcList(response.data.dcData);
              setpageCount(response.data.pageCount);
            }
          }
        } else navigate("/");
      }
    };
    getDCbyDcNumber();
  }, [cookies, navigate, value, functionCall, dcNum]);

  return (
    <Box sx={{ mt: { md: 5.5, xs: 1, minHeight: "100vh" } }}>
      {!open && (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: { md: "block", xs: "none" } }}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "25px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  ml: 8,
                }}
              >
                DC
              </Typography>
            </Box>

            <Avatar
          alt={cookies?.vcams_user.name}
          src={cookies?.vcams_user.photo}
          sx={{
            width: 72,
            height: 72,
            ml: 8,
            bgcolor: "red",fontSize:'30px',letterSpacing:1,
            display: { md: "flex", xs: "none" },
          }}
        >
          {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
        </Avatar>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: { md: "96%", xs: "98%" },
              m: "auto",
            }}
          >
            <Button
              sx={{
                borderRadius: "10px",
                background: "red",
                color: "black",
                height: "32px",
                pl: { md: 2, xs: 1 },
                pr: { md: 2, xs: 1 },
                textTransform: "none",
                minWidth: "180px",
                whiteSpace: "nowrap",
                ml: { md: 3.5, xs: 0 },
                mt: 5,
                mb: 3,
              }}
              onClick={() => {
                // setCustomerInfo({});
                // setdcNumber("");
                // setdcCreator("");
                // setdcCheckedBy("");
                // setremarks("");
                // setItemDetails([]);
                setdcOpeningType("CREATE");
                setOpen(true);
                // setdcIssuedBy("");
              }}
            >
              New DC +
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              m: "auto",
              mt: 3,
            }}
          >
            <input
              type="date"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#8A8A8A63",
                height: "30px",
                marginTop: "1px",
                minWidth: "180px",
                marginBottom: "10px",
              }}
              value={startDate}
              onChange={(e) => {
                setstartDate(e.target.value);
                setfunctionCall("DCBYDATE");
              }}
            />
            <input
              type="date"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#8A8A8A63",
                height: "30px",
                marginTop: "1px",
                minWidth: "180px",
                marginBottom: "10px",
              }}
              value={endDate}
              onChange={(e) => {
                setendDate(e.target.value);
                setfunctionCall("DCBYDATE");
              }}
            />

            <Autocomplete
              id="outlined-select-Name"
              sx={{
                minWidth: 230,
                mt: -1,
                mb: { md: 0, xs: -1.7 },
                ml: { md: 0, xs: 1.3 },
              }}
              options={nameSuggestions.map(
                (suggestion) => suggestion.customerName
              )}
              value={customer || ""}
              freeSolo={true}
              onChange={(event, newValue) => {
                setcustomer(newValue);
                setfunctionCall("DCBYCUSTOMER");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={customer || ""}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "black",
                      textAlign: "center",
                      letterSpacing: "8px",
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "#8A8A8A63",
                      height: "17px",
                    },
                    mb: 2,
                    mr: 1,
                    ml: 1,
                  }}
                  placeholder="Customer"
                  onChange={(event) => {
                    setcustomer(event.target.value);
                    setfunctionCall("DCBYCUSTOMER");
                  }}
                />
              )}
            />

            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "black",
                  textAlign: "center",
                  letterSpacing: "8px",
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "#8A8A8A63",
                  height: ".01em",
                },
                mb: 2,
                mr: 1,
                ml: 1,
              }}
              value={dcNum}
              placeholder="DC Number"
              onChange={(e) => {
                setdcNum(e.target.value);
                setfunctionCall("DCBYNUMBER");
              }}
            />

            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "black",
                  textAlign: "center",
                  letterSpacing: "8px",
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "#8A8A8A63",
                  height: ".01em",
                },
                mb: 2,
                mr: 1,
                ml: 1,
              }}
              value={dcItem}
              placeholder="Item Name"
              onChange={(e) => {
                setdcItem(e.target.value);
                setfunctionCall("DCBYITEM");
              }}
            />
          </Box>
          <Box
            sx={{
              width: { md: "96%", xs: "96%" },
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              m: "auto",
              mt: 2,
              mb: 8,
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                maxWidth: { md: "100%", xs: "98%" },
                mt: 2,
                mb: 2,
              }}
              aria-expanded="true"
            >
              <TableContainer component={Paper}>
                {/* Header */}
                <Table style={{ display: "block" }}>
                  <TableHead>
                    <TableRow style={{ pageBreakInside: "avoid" }}>
                      <TableCell style={{ minWidth: 50 }}>
                        <b>Sl. no</b>
                      </TableCell>
                      <TableCell style={{ minWidth: 300 }}>
                        <b>Customer Name</b>
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }}>
                        <b>Phone Number</b>
                      </TableCell>
                      <TableCell style={{ minWidth: 120 }}>
                        <b> DC Number</b>
                      </TableCell>
                      <TableCell style={{ minWidth: 120 }}>
                        <b>Status</b>
                      </TableCell>
                      <TableCell style={{ minWidth: 200 }}>
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>

                {/* Body - Scrollable */}
                <Table
                  style={{
                    // overflowY: "auto",
                    display: "block",
                    // maxHeight: 400,
                  }}
                >
                  <TableBody>
                    {dcList.map((row, index) => (
                      <TableRow
                        key={index}
                        style={{
                          pageBreakInside: "avoid",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell style={{ minWidth: 50 }}>
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ minWidth: 300 }}>
                          {row.name}
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {row.mobileNumber}
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }}>
                          {row.dcNumber}
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }}>
                          {row.dcStatus}
                        </TableCell>
                        <TableCell style={{ width: 200 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <Button
                              onClick={() => {
                                getDcToUpdate(row.dcId);
                              }}
                              sx={{
                                borderRadius: "10px",
                                background: "#008000bd",
                                color: "black",
                                height: "32px",
                                textTransform: "none",
                                whiteSpace: "nowrap",
                                minWidth: "50px",
                                "&:hover": {
                                  background: "green",
                                  color: "white",
                                },
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              sx={{
                                borderRadius: "10px",
                                background: "#545de4f7",
                                color: "black",
                                height: "32px",
                                textTransform: "none",
                                whiteSpace: "nowrap",
                                minWidth: "50px",
                                "&:hover": {
                                  background: "#545de4f7",
                                  color: "white",
                                },
                              }}
                              onClick={() => navigate(`/check-dc/${row.dcId}`)}
                            >
                              Return
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow sx={{ maxWidth: "96%" }}>
                      <TableCell colSpan={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: {
                              md: "flex-end",
                              xs: "flex-start",
                            },
                            maxWidth: { xs: 320, sm: 480 },
                            bgcolor: "background.paper",
                            marginLeft: { md: "auto", xs: "0px" },
                            marginRight: { xs: "auto", md: "0px" },
                          }}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            TabIndicatorProps={{
                              style: { display: "none", maxHeight: "5px" },
                            }}
                          >
                            {numberArray.map((num, index) => (
                              <Tab
                                key={index}
                                label={num}
                                sx={{
                                  minHeight: "auto",
                                  maxHeight: 0.5,
                                  minWidth: "auto",
                                  ...(value === index && {
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                  }),
                                }}
                                style={
                                  value === index ? { color: "white" } : {}
                                }
                              />
                            ))}
                          </Tabs>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      )}

      {open && (
        <Box>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              "& .MuiDialog-paperWidthSm": {
                maxWidth: "100vw",
                minWidth: "100vw",
              },
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: -2 }}
            >
              <Box
                sx={{
                  fontSize: "18px",
                  letterSpacing: 1,
                  fontWeight: 500,
                  color: "#433e3e",
                }}
              >
                {dcOpeningType === "UPDATEDC" ? "Update" : " Create New"} DC
              </Box>
              <Box
                sx={{
                  fontSize: "18px",
                  letterSpacing: 1,
                  fontWeight: 500,
                  color: "#433e3e",
                }}
              >
                <ClearIcon
                  onClick={() => setOpen(false)}
                  sx={{
                    cursor: "pointer",
                    mt: 0.8,
                    "&:hover": { backgroundColor: "#8A8A8A63" },
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <BillingPage />
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: { md: "right", xs: "center" },
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => {
                setCustomerInfo({
                  name: "",
                  customerId: "",
                  mobileNumber: "",
                  packTime: "",
                  dateOut: "",
                  timeOut: "",
                  shootDate: "",
                  deliveredBy: "",
                  collectedBy: "",
                  crew: "",
                });
                setdcNumber("");
                setdcCreator("");
                setdcCheckedBy("");
                setremarks("");
                setItemDetails([]);
                setdcOpeningType("CREATE");
                setdcIssuedBy("");
              }}
              sx={{
                borderRadius: "10px",
                background: "#e454d4f7",
                color: "black",
                height: { md: "32px", xs: "25px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                minWidth: { md: "120px", xs: "90px" },
                "&:hover": {
                  background: "#e454d4f7",
                  color: "white",
                },
              }}
            >
              New DC
            </Button>

            <Button
              onClick={dcOpeningType === "UPDATEDC" ? updateDC : saveDc}
              sx={{
                borderRadius: "10px",
                background: "#008000bd",
                color: "black",
                height: { md: "32px", xs: "25px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                minWidth: { md: "120px", xs: "90px" },
                "&:hover": {
                  background: "green",
                  color: "white",
                },
              }}
            >
              {dcOpeningType === "UPDATEDC" ? "UPDATE" : "SAVE"}
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                borderRadius: "10px",
                background: "#ff0000d4",
                color: "black",
                height: { md: "32px", xs: "25px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                minWidth: { md: "120px", xs: "90px" },
                "&:hover": {
                  background: "red",
                  color: "white",
                },
              }}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Box>
      )}
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "DC Deleted successfully" ? "red" : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
    </Box>
  );
};

export default Dc;
