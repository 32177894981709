import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { config } from "../../config/Config";
import { apiList, invokeApi, invokeFormDataApi } from "../../apis/ApiServices";
import { useCookies } from "react-cookie";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { userContext } from "../../useContext/CreateContext";

const Inventory = () => {
  const componentRef = useRef();
  const [open, setOpen] = useState(false);
  const [qrCode, setqrCode] = useState(null);
  const [barCode, setbarCode] = useState(null);
  const [itemName, setitemName] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [type, settype] = useState("");
  const [scannerType, setScannerType] = useState("QR Code");
  const [items, setItems] = useState([""]);
  const [tableData, setTableData] = useState([]);
  const [dataAdded, setdataAdded] = useState(false);
  const [barcodeVisibility, setbarcodeVisibility] = useState(false);
  const [serialNumber, setserialNumber] = useState("");
  const [positionNumber, setpositionNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const barcodeRef = useRef(null);
  const {
    searchByCategory,
    setsearchByCategory,
    searchBySubCategory,
    setsearchBySubCategory,
  } = React.useContext(userContext);

  const handleInputChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  const handleAddMore = () => {
    setItems([...items, ""]);
  };

  const handleDelete = (index) => {
    if (index > 0) {
      const newItems = [...items];
      newItems.splice(index, 1);
      setItems(newItems);
    } else {
      return;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const saveImagePath = useCallback(
    async ({ imagePath, uniqueCode }) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        // setLoading(true);
        let params = {
          uniqueCode,
          imagePath,
        };

        let response = await invokeApi(
          config.apiDomains + apiList.updateProductScanner,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
        } else {
          // alert(
          //   "Something went wrong while saving property data. Please try again later!!"
          // );
          setLoading(false);
        }
        setLoading(false);
      } else navigate("/");
    },
    [cookies, navigate]
  );

  const uploadImageGetPath = useCallback(
    async (file, codeType) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        try {
          let formData = new FormData();
          formData.append("scannerImage", file);
          formData.append("path", "v_cams_gallery");

          let response = await invokeFormDataApi(
            config.apiDomains + apiList.uploadFile,
            formData,
            cookies
          );

          if (response.status >= 200 && response.status < 300) {
            if (codeType === "QR") {
              saveImagePath({
                imagePath: response.data.imagePath,
                uniqueCode: qrCode,
              });
            } else if (codeType === "Barcode") {
              saveImagePath({
                imagePath: response.data.imagePath,
                uniqueCode: barCode,
              });
            }
          } else {
            console.error(
              "Error while uploading image. HTTP status:",
              response.status
            );
          }
        } catch (error) {
          console.error("Error while uploading image:", error);
        }
      } else navigate("/");
    },
    [cookies, saveImagePath, qrCode, barCode, navigate]
  );

  const generateBarcodeImage = useCallback(async () => {
    try {
      const canvas = await html2canvas(barcodeRef.current);

      const dataUrl = canvas.toDataURL("image/png");
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const barCodeImageFile = await new File([blob], `bar-${barCode}.png`);

      // Call the upload function here after barcode image generation is complete

      if (barCode) {
        uploadImageGetPath(barCodeImageFile, "Barcode");
        setbarcodeVisibility(false);
      } else return null;
    } catch (error) {
      console.error("Error while generating barcode image:", error);
    }
  }, [barCode, uploadImageGetPath]);

  // Function to generate QR code image file
  const generateQrCodeImage = useCallback(async () => {
    try {
      const canvas = document.getElementById("qrcode");
      const dataUrl = canvas.toDataURL("image/png");
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const qrCodeImageFile = await new File([blob], `qr-${qrCode}.png`);

      // Call the upload function here after QR code image generation is complete
      if (qrCode) {
        uploadImageGetPath(qrCodeImageFile, "QR");
      } else return null;
    } catch (error) {
      console.error("Error while generating QR code image:", error);
    }
  }, [qrCode, uploadImageGetPath]);

  // Call the async functions

  useEffect(() => {
    if (qrCode) generateQrCodeImage();
    else if (barCode) generateBarcodeImage();
  }, [barCode, generateBarcodeImage, qrCode, generateQrCodeImage]);
  const addItem = async () => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      setLoading(true);
      let params = {
        itemName,
        serialNumber,
        category: category,
        subCategory: subCategory,
        type,
        scannerType,
        itemDetails: items,
        positionNumber,
      };

      let response = await invokeApi(
        config.apiDomains + apiList.addProduct,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        if (scannerType === "QR Code") {
          setqrCode(response.data.uniqueCode);
          setbarCode(null);
        } else if (scannerType === "Bar Code") {
          setbarCode(response.data.uniqueCode);
          setqrCode(null);
          setbarcodeVisibility(true);
        }
        setdataAdded(true);
        setsnackOpen(true);
        setsnackMessage("Product saved successfully");
      } else {
        // alert(
        //   "Something went wrong while saving property data. Please try again later!!"
        // );
        setLoading(false);
      }
      setLoading(false);
    } else navigate("/");
  };

  const getItemsByCategory = useCallback(
    async (cat, subCat) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        setLoading(true);
        let params = { category: cat, subCategory: subCat };

        let response = await invokeApi(
          config.apiDomains + apiList.getProductsByCategory,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          const dataWithSerialNumber = response.data.itemData.map(
            (row, index) => ({
              ...row,
              id: index + 1, // Assuming you want the serial number to start from 1
            })
          );
          setTableData(dataWithSerialNumber);
        } else {
          setTableData([]);
          // alert(response.data.message);
          setLoading(false);
        }

        setLoading(false);
      } else navigate("/");
    },
    [cookies, navigate]
  );

  useEffect(() => {
    const getCategoriesId = async (cat, subCat) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        // setLoading(true);
        let params = { category: cat, subCategory: subCat };

        let response = await invokeApi(
          config.apiDomains + apiList.getCategoriesId,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          setCategories(response.data.categoryData);
        } else {
          // alert(
          //   "Something went wrong while saving property data. Please try again later!!"
          // );
        }
        // setLoading(false);
        // setActiveStep(0);
      } else navigate("/");
    };
    getCategoriesId();
  }, [cookies, navigate]);

  useEffect(() => {
    const foundCategory = categories.find(c => c.categoryId === category);
    setSubCategories(foundCategory ? foundCategory.subcategory : []);
    setSubCategory('');
  }, [category, categories]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  useEffect(() => {
    getItemsByCategory(searchByCategory, searchBySubCategory);
  }, [dataAdded, getItemsByCategory, searchByCategory, searchBySubCategory]);

  return (
    <Box sx={{ mt: { md: 5.5, xs: 1, minHeight: "100vh" } }}>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setsnackOpen(false);
          }}
        >
          <SnackbarContent
            style={{
              color: "white",
              backgroundColor:
                snackMessage === "Category Deleted successfully"
                  ? "red"
                  : "green", // Change this to the desired color
            }}
            message={snackMessage}
          />
        </Snackbar>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              ml: 8,
            }}
          >
            Inventory
          </Typography>
        </Box>
        <Avatar
          alt={cookies?.vcams_user.name}
          src={cookies?.vcams_user.photo}
          sx={{
            width: 72,
            height: 72,
            ml: 8,
            bgcolor: "red",
            fontSize: "30px",
            letterSpacing: 1,
            display: { md: "flex", xs: "none" },
          }}
        >
          {cookies?.vcams_user.name?.substring(0, 2).toUpperCase()}
        </Avatar>
      </Box>

      <Grid
        container
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { md: 8, xs: 5 },
        }}
      ></Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { md: "95%", xs: "98%" },
          m: "auto",
          flexWrap: "wrap",
          spacing: 3,
          gap: 3,
        }}
      >
        <Button
          sx={{
            borderRadius: "10px",
            minHeight: "33.5px",
            pl: { md: 2, xs: 1 },
            pr: { md: 2, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            maxWidth: "180px",
            minWidth: { md: "180px", xs: "100px" },
            background: "#ff0000d4",
            color: "black",
            "&:hover": {
              background: "red",
              color: "white",
            },
          }}
          onClick={() => {
            setOpen(true);
            setitemName("");
            setserialNumber("");
            setCategory("");
            setSubCategory("");
            settype("");
            setpositionNumber("");
            setItems([""]);
          }}
        >
          New Product +
        </Button>
        <Button
          onClick={handlePrint}
          sx={{
            display: { md: "none", xs: "flex" },
            borderRadius: "10px",
            background: "#000000db",
            color: "white",
            minHeight: "33.5px",
            maxWidth: "180px",
            minWidth: { md: "180px", xs: "100px" },
            pl: { md: 2, xs: 1 },
            pr: { md: 2, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            "&:hover": {
              background: "#000000db",
              color: "red",
            },
            mr: 0.2,
          }}
        >
          Print
        </Button>

        <select
          id="cars"
          name="cars"
          style={{
            border: "none",
            borderRadius: "10px",
            background: "#8A8A8A63",
            paddingLeft: "10px",
            minHeight: "33.5px",
            maxWidth: "180px",
            minWidth: "180px",
            letterSpacing: 1,
          }}
          onChange={(event) => {
            setsearchByCategory(event.target.value);
            handleCategoryChange(event);
          }}
          value={searchByCategory}
        >
          <option value="" style={{ color: "red" }}>
            Catagory
          </option>
          {categories.map((cat, index) => (
            <option key={index} value={cat.categoryId}>
              {cat.category}
            </option>
          ))}
        </select>
        <select
          id="cars"
          name="cars"
          style={{
            border: "none",
            borderRadius: "10px",
            background: "#8A8A8A63",
            paddingLeft: "10px",
            minHeight: "33.5px",
            maxWidth: "180px",
            minWidth: "180px",
            letterSpacing: 1,
          }}
          onChange={(event) => {
            setsearchBySubCategory(event.target.value);
            handleSubCategoryChange(event);
          }}
          value={searchBySubCategory}
        >
          <option value="" style={{ color: "red" }}>
            Subcategory
          </option>
          {subCategories.map((sub, subIndex) => (
            <option key={subIndex} value={sub.subcategoryId}>
              {sub.subcategoryName}
            </option>
          ))}
        </select>
        <Button
          onClick={handlePrint}
          sx={{
            display: { md: "flex", xs: "none" },
            borderRadius: "10px",
            background: "#000000db",
            color: "white",
            minHeight: "33.5px",
            maxWidth: "180px",
            minWidth: { md: "180px", xs: "100px" },
            pl: { md: 2, xs: 1 },
            pr: { md: 2, xs: 1 },
            textTransform: "none",
            whiteSpace: "nowrap",
            "&:hover": {
              background: "#000000db",
              color: "red",
            },
          }}
        >
          Print
        </Button>
        {/* <TextField
          sx={{
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder":
              {
                color: "black",
                textAlign: "center",
                letterSpacing: "8px",
              },
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              backgroundColor: "#8A8A8A63",
              height: ".01em", borderRadius:'10px'
            },
            
            
          }}
          placeholder="Search Here"
        />   */}
      </Box>
      <Dialog
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 700,
            minWidth: { md: 700, xs: 250 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
                mb: 3,
              }}
            >
              Add New Item
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                letterSpacing: 1,
                fontWeight: 500,
                color: "#433e3e",
              }}
            >
              <ClearIcon
                onClick={() => setOpen(false)}
                sx={{
                  cursor: "pointer",
                  mt: 0.8,
                  "&:hover": { backgroundColor: "#8A8A8A63" },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              spacing={2}
            >
              <Grid item md={6} xs={12}>
                <input
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    letterSpacing: 1,
                    paddingTop: "8px",
                    paddingBottom: "10px",
                    outline: "none",
                    minWidth: "91.5%",
                  }}
                  onChange={(event) => setitemName(event.target.value)}
                  value={itemName}
                  placeholder="Item Name"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <select
                  id="cars"
                  name="cars"
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    minHeight: "33.5px",
                    width: "95%",
                    paddingTop: "7px",
                    letterSpacing: 1,
                  }}
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value="" style={{ color: "red" }}>
                    Catagory
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat.categoryId}>
                      {cat.category}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item md={6} xs={12}>
                {" "}
                <select
                  id="cars"
                  name="cars"
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    minHeight: "33.5px",
                    paddingTop: "7px",
                    paddingLeft: "10px",
                    minWidth: "95%",
                    letterSpacing: 1,
                  }}
                  value={subCategory}
                  onChange={handleSubCategoryChange}
                >
                  <option value="" style={{ color: "red" }}>
                    Subcategory
                  </option>
                  {(
                    categories.find((cat) => cat.categoryId === category)?.subcategory || []
                  ).map((sub, subIndex) => (
                    <option key={subIndex} value={sub.subcategoryId}>
                      {sub.subcategoryName}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item md={6} xs={12}>
                <select
                  id="cars"
                  name="cars"
                  style={{
                    border: "none",
                    // borderRadius: "10px"  ,
                    background: "#8A8A8A63",
                    minHeight: "33.5px",
                    paddingTop: "7px",
                    paddingLeft: "10px",
                    minWidth: "95%",
                    letterSpacing: 1,
                  }}
                  onChange={(event) => settype(event.target.value)}
                  value={type}
                >
                  <option value="Type" style={{ color: "red" }}>
                    Type
                  </option>
                  <option value="Single">Single</option>
                  <option value="Multiple">Multiple</option>
                </select>
              </Grid>

              <Grid item md={6} xs={12}>
                <input
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    letterSpacing: 1,
                    paddingTop: "8px",
                    paddingBottom: "10px",
                    outline: "none",
                    minWidth: "91.5%",
                  }}
                  onChange={(event) => setserialNumber(event.target.value)}
                  value={serialNumber}
                  placeholder="Serial Number"
                />
              </Grid>
              <Grid item md={6} xs={12} mb={{ md: 0, xs: 2 }}>
                <input
                  style={{
                    border: "none",
                    // borderRadius: "10px",
                    background: "#8A8A8A63",
                    paddingLeft: "10px",
                    letterSpacing: 1,
                    paddingTop: "8px",
                    paddingBottom: "10px",
                    outline: "none",
                    minWidth: "91.5%",
                  }}
                  onChange={(event) => setpositionNumber(event.target.value)}
                  value={positionNumber}
                  placeholder="Position Number"
                />
              </Grid>
              <Grid item md={12} xs={12} mb={{ md: 2, xs: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      color: "#000000bf",
                      letterSpacing: 1,
                      fontSize: "17px",
                    }}
                  >
                    Type of Scanner:
                  </Box>
                  <Box>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        ".css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                          {
                            color: "black",
                          },
                        "@media (max-width: 600px)": {
                          flexDirection: "column",
                        },
                        gap: { md: 2, xs: 0 },
                      }}
                      onChange={(event) => {
                        setScannerType(event.target.value);
                      }}
                      value={scannerType}
                    >
                      <FormControlLabel
                        value="QR Code"
                        control={<Radio />}
                        label="QR Code"
                      />
                      <FormControlLabel
                        value="Bar Code"
                        label="Bar Code"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {type !== "Multiple" && (
              <Box
                sx={{
                  m: "auto",
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    color: "#000000bf",
                    letterSpacing: 1,
                    fontSize: "17px",
                    mb: 1,
                  }}
                >
                  Please provide the details of the Item:
                </Box>
                <div style={{}}>
                  {items.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 0.5,
                        marginTop: index > 0 ? 2 : 0,
                      }}
                    >
                      <input
                        style={{
                          border: "none",
                          // borderRadius: "10px",
                          background: "#8A8A8A63",
                          paddingLeft: "10px",
                          letterSpacing: 1,
                          paddingTop: "8px",
                          width: "100%",
                          paddingBottom: "10px",
                          outline: "none",
                        }}
                        placeholder="Item Details"
                        value={item}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />

                      <DeleteIcon
                        onClick={() => handleDelete(index)}
                        sx={{ cursor: "pointer", color: "#ff1010e3", mt: 0.8 }}
                      />
                    </Box>
                  ))}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                      marginTop: 2,
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: "10px",
                        background: "#8A8A8A63",
                        color: "black",
                        height: { md: "32px", xs: "25px" },
                        pl: { md: 2, xs: 1 },
                        pr: { md: 2, xs: 1 },
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        maxWidth: "150px",
                        minWidth: { md: "150px", xs: "50px" },
                      }}
                      onClick={handleAddMore}
                    >
                      Add More +
                    </Button>
                  </Box>
                </div>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: { md: "right", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#ff0000d4",
              color: "black",
              height: { md: "32px", xs: "25px" },
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              addItem();
              if (type === "Multiple") setOpen(true);
              else setOpen(false);
            }}
            sx={{
              borderRadius: "10px",
              background: "#008000bd",
              color: "black",
              height: { md: "32px", xs: "25px" },
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "green",
                color: "white",
              },
            }}
          >
            Add Item
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: { md: "96%", xs: "96%" },
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          m: "auto",
          mt: 2,
          mb: 8,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",
            mt: 2,
            mb: 2,

            "& .MuiDataGrid-withBorderColor": {
              border: "none !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root": {
              color: "black !important",
            },

            "& .css-wop1k0-MuiDataGrid-footerContainer": {
              display: "none !important",
            },
            "& .css-j204z7-MuiFormControlLabel-root": {
              display: "none !important",
            },
            "& .css-204u17-MuiDataGrid-main ": {
              pl: "20px !important",
            },
            "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root ": {
              pl: "25px !important",
            },
            "& .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:hover, .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered":
              {
                backgroundColor: "white !important",
              },
          }}
          aria-expanded="true"
        >
          <Box sx={{ width: 0.99 }}>
            <TableContainer component={Paper} ref={componentRef}>
              {/* Header */}
              <Table style={{ display: "block" }}>
                <TableHead>
                  <TableRow style={{ pageBreakInside: "avoid" }}>
                    <TableCell style={{ width: 50 }}>Sl. no</TableCell>
                    <TableCell style={{ width: 400 }}>Item Name</TableCell>
                    <TableCell style={{ width: 200 }}>Category</TableCell>
                    <TableCell style={{ width: 200 }}>Sub-category</TableCell>
                    <TableCell style={{ width: 50 }}>Count</TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {/* Body - Scrollable */}
              <Table
                style={{
                  borderCollapse: "separate",
                  // overflowY: "auto",
                  display: "block",
                  // maxHeight: 400,
                }}
              >
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{ pageBreakInside: "avoid", cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/product-by-name/${row.itemName}`);
                      }}
                    >
                      <TableCell style={{ width: 50 }}>{row.positionNumber}</TableCell>
                      <TableCell style={{ width: 400 }}>
                        {row.itemName}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {row.category}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {row.subCategory}
                      </TableCell>
                      <TableCell style={{ width: 50 }}>
                        {row.itemCount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        color="primary" aria-expanded="true"
        // startIcon={<SaveAltIcon />}
        onClick={handleExportExcelClick}
      >
        Export as Excel
      </Button> */}
      <Button
        onClick={() => navigate("/recycle-inventory")}
        sx={{
          borderRadius: "10px",
          background: "#000000db",
          color: "white",
          minHeight: "33.5px",
          maxWidth: "100px",
          minWidth: { md: "100px", xs: "100px" },
          pl: { md: 2, xs: 1 },
          pr: { md: 2, xs: 1 },
          textTransform: "none",
          whiteSpace: "nowrap",
          "&:hover": {
            background: "#000000db",
            color: "red",
          },
          mr: 0.2,
          mb: 5,
        }}
      >
        <RecyclingIcon />
      </Button>
      <div style={{ display: "none" }}>
        <QRCode id="qrcode" value={qrCode} />
      </div>
      {barcodeVisibility && (
        <div ref={barcodeRef}>
          <Barcode value={barCode} />
        </div>
      )}
    </Box>
  );
};

export default Inventory;
