import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";
import BarcodeScanner from "react-barcode-reader";
import { QrReader } from "react-qr-reader";

import {
  Autocomplete,
  Box,
  Button,
  TextField,
  // CircularProgress,
  // Dialog,
  // DialogTitle,
  // Snackbar,
  // SnackbarContent,
} from "@mui/material";
import logo from "../../../images/logo2.png";
import { apiList, invokeApi } from "../../apis/ApiServices";
import { config } from "../../config/Config";
import { useCookies } from "react-cookie";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";
import { userContext } from "../../useContext/CreateContext";
import { useNavigate } from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  editableField: {
    width: 70, // Adjust the width based on your design
  },
}));

const BillingPage = () => {
  const classes = useStyles();
  // const componentRef = useRef();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  // State to store customer information

  const {
    itemDetails,
    setItemDetails,
    customerInfo,
    setCustomerInfo,
    printSignal,
    setprintSignal,
    dcIssuedBy,
    setdcIssuedBy,
    dcCheckedBy,
    setdcCheckedBy,
    remarks,
    setremarks,
    dcOpeningType,
    dcNumber,scanVisible
  } = useContext(userContext);

  // Set up the ReactToPrint hook
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // Call this function to trigger the print
  useEffect(() => {
    if (printSignal) {
      // handlePrint();
      setprintSignal(false);
    } else return;
  }, [printSignal, setprintSignal]);

  const [isFetching, setIsFetching] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [scanControll, setscanControll] = useState(false);
  const [torchOn, setTorchOn] = useState(false);

  const fetchNameSuggestions = useCallback(
    async (input) => {
      if (new Date(cookies.vcams_sess.expiry) > new Date()) {
        let params = { customerName: "" };

        let response = await invokeApi(
          config.apiDomains + apiList.getCustomerByName,
          params,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          setNameSuggestions(response.data.customerData);
        }
      } else navigate("/");
    },
    [cookies, navigate]
  );

  useEffect(() => {
    fetchNameSuggestions();
  }, [fetchNameSuggestions]);
  const handleScan = async (data) => {
    if (new Date(cookies.vcams_sess.expiry) > new Date()) {
      if (data && !isFetching) {
        setscanControll(false);
        setIsFetching(true);
        try {
          let params = { uniqueCode: data };
          let response = await invokeApi(
            config.apiDomains + apiList.getProductFromCode,
            params,
            cookies
          );
          const itemDetail = await response.data.itemData;
          itemDetail.quantity = 1;
          // Check if the uniqueId already exists in itemDetails
          if (
            !itemDetails.some(
              (item) => item.uniqueCode === itemDetail.uniqueCode
            )
          ) {
            setscanControll(false);
            // Add the fetched item detail to the array if uniqueId doesn't exist
            itemDetail.dcItemId = "";
            setItemDetails((prevDetails) => [...prevDetails, itemDetail]);
          } else if (
            itemDetails.some(
              (item) => item.uniqueCode === itemDetail.uniqueCode
            ) &&
            dcOpeningType === "UPDATEDC"
          ) {
            setscanControll(false);
            // Update the itemStatus to "pending" for the item with the matching uniqueCode
            setItemDetails((prevDetails) =>
              prevDetails.map((item) =>
                item.uniqueCode === itemDetail.uniqueCode &&
                item.itemStatus !== "deleted"
                  ? { ...item, itemStatus: "deleted", status: "inactive" }
                  : item.uniqueCode === itemDetail.uniqueCode &&
                    item.itemStatus === "deleted"
                  ? { ...item, itemStatus: "pending", status: "active" }
                  : item
              )
            );
          } else {
            setscanControll(false);
            // Show alert if the uniqueId already exists
            alert("You scanned the same item Twice.!!");
          }
        } catch (error) {
          console.error("Error fetching item details:", error);
        } finally {
          setIsFetching(false);
        }
      }
    } else navigate("/");
  };


  const handleError = (err) => {
    console.error("Error scanning:", err);
  };

  const renderScanner2 = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Use QR code reader for mobile devices
      return <QrReader onScan={handleScan} onError={handleError} />;
    } else {
      // Use barcode scanner for other devices
      return <BarcodeScanner onScan={handleScan} onError={handleError} />;
    }
  };



  const renderScanner = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return (
        <Box
          sx={{
            border: "2px solid black",
            borderRadius: "5px",
            overflow: "hidden",
            maxWidth: 280,
            maxHeight: 280,
            m: "auto",
          }}
        >
          <BarcodeScannerComponent
            width={320}
            height={280}
            torch={torchOn}
            facingMode="environment"
            onUpdate={(err, result) => {
              if (result) handleScan(result.text);
              else console.log(err);
            }}
          />
        </Box>
      );
    } else return;
  };

  const consolidateItemDetails = (itemDetails) => {
    const consolidatedMap = new Map();

    itemDetails.forEach((row) => {
      if (row.itemStatus !== "deleted") {
        const key = `${row.itemName}-${row.type}`;
        if (consolidatedMap.has(key)) {
          const consolidatedRow = consolidatedMap.get(key);

          // Convert quantity to number before adding
          consolidatedRow.quantity += Number(row.quantity);

          consolidatedRow.serialNumbers.push(row.serialNumber);
        } else {
          consolidatedMap.set(key, {
            ...row,
            quantity: Number(row.quantity), // Convert quantity to number
            serialNumbers: [row.serialNumber],
          });
        }
      }
    });

    const consolidatedItemDetails = Array.from(consolidatedMap.values());

    return consolidatedItemDetails;
  };

  const consolidatedItemDetails = consolidateItemDetails(itemDetails);

  const componentRef = useRef();

  return (
    <Box>
      <Box>
        <Paper
          className={classes.root}
          ref={componentRef}
          style={{ boxShadow: "none" }}
        >
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                border: "1px solid #000",
                paddingBottom: "5px",
                pl: 2,
                mb: -2,
              }}
            >
              <Box sx={{ width: "30%", borderRight: "1px solid #000" }}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    pt: 0.5,
                  }}
                >
                  <b> DC Issued By</b>
                  <b>:</b>
                  <input
                    name="mobileNumber"
                    value={dcIssuedBy}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                      marginTop: "-2px",
                    }}
                    onChange={(e) => setdcIssuedBy(e.target.value)}
                  />
                </Box>

                <Box
                  sx={{
                    pr: 2,
                    maxHeight: 200,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "black",
                      display: "flex",
                      mt: 1,
                      objectFit: "fit",
                      width: "100%",
                      height: 75,
                    }}
                    component="img"
                    src={logo}
                  />
                </Box>
                <Box sx={{ ml: -2 }}>
                  <Typography style={{ textAlign: "center", fontSize: "9px" }}>
                    <b>No.3, 2nd cross Rd, 2nd main Rd, Kanakpura Rd,</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "9px" }}>
                    <b>Vivekananda colony, 1st phase, J.P Nagar,</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "9px" }}>
                    <b>Bengaluru, Karnataka 560078</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "9px" }}>
                    <b>Website : www.vcams.in</b>
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "9px" }}>
                    <b>PH NO : 9901999694, 9901999697</b>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "70%" }}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "35px" }}>DC Number</b>
                    <b>:</b>
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "9px",
                      marginLeft: "5px",
                    }}
                  >
                    {dcNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "32.3px" }}>Client Name</b>
                    <b>:</b>
                  </Typography>

                  <Autocomplete
                    id="outlined-select-Name"
                    fullWidth
                    sx={{ maxHeight: "5px", ml: -1 }}
                    options={nameSuggestions.map(
                      (suggestion) => suggestion.customerName
                    )}
                    value={customerInfo.name}
                    freeSolo={true}
                    onChange={(e, newValue) => {
                      const selectedName = newValue || e.target.value;

                      // Find the corresponding phone number based on the selected name
                      const selectedCustomer = nameSuggestions.find(
                        (suggestion) => suggestion.customerName === selectedName
                      );

                      // Update state with the selected name and corresponding phone number
                      setCustomerInfo({
                        ...customerInfo,
                        name: selectedName,
                        mobileNumber: selectedCustomer
                          ? selectedCustomer.contactNumber
                          : "",
                        customerId: selectedCustomer
                          ? selectedCustomer.customerId
                          : "",
                      });

                      // Call the API for name suggestions
                      fetchNameSuggestions(selectedName);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={customerInfo.name}
                        variant="outlined"
                        sx={{
                          minWidth: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "& .MuiInputBase-input": {
                            height: "0px",
                            fontSize: "10px",
                          },
                          mt: -1,
                        }}
                        onChange={(e, newValue) => {
                          const selectedName = newValue || e.target.value;

                          // Find the corresponding phone number based on the selected name
                          const selectedCustomer = nameSuggestions.find(
                            (suggestion) =>
                              suggestion.customerName === selectedName
                          );

                          // Update state with the selected name and corresponding phone number
                          setCustomerInfo({
                            ...customerInfo,
                            name: selectedName,
                            mobileNumber: selectedCustomer
                              ? selectedCustomer.contactNumber
                              : customerInfo.mobileNumber,
                            customerId: selectedCustomer
                              ? selectedCustomer.customerId
                              : customerInfo.customerId,
                          });

                          // Call the API for name suggestions
                          fetchNameSuggestions(selectedName);
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "20.5px" }}>Phone Number</b>
                    <b>:</b>
                  </Typography>
                  <Autocomplete
                    id="outlined-select-Number"
                    fullWidth
                    sx={{ maxHeight: "5px", ml: -1 }}
                    options={nameSuggestions.map(
                      (suggestion) => suggestion.contactNumber
                    )}
                    value={customerInfo.mobileNumber}
                    freeSolo={true}
                    onChange={(e, newValue) => {
                      const selectedNumber = newValue || e.target.value;

                      // Find the corresponding name based on the selected number
                      const selectedCustomer = nameSuggestions.find(
                        (suggestion) =>
                          suggestion.contactNumber === selectedNumber
                      );

                      // Update state with the selected number and corresponding name
                      setCustomerInfo({
                        ...customerInfo,
                        mobileNumber: selectedNumber,
                        name: selectedCustomer
                          ? selectedCustomer.customerName
                          : customerInfo.name,
                        customerId: selectedCustomer
                          ? selectedCustomer.customerId
                          : customerInfo.customerId,
                      });

                      // Call the API for name suggestions
                      fetchNameSuggestions(selectedNumber);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={customerInfo.mobileNumber}
                        variant="outlined"
                        sx={{
                          minWidth: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "& .MuiInputBase-input": {
                            height: "0px",
                            fontSize: "10px",
                          },
                          mt: -1,
                        }}
                        onChange={(e, newValue) => {
                          const selectedNumber = newValue || e.target.value;

                          // Find the corresponding name based on the selected number
                          const selectedCustomer = nameSuggestions.find(
                            (suggestion) =>
                              suggestion.contactNumber === selectedNumber
                          );

                          // Update state with the selected number and corresponding name
                          setCustomerInfo({
                            ...customerInfo,
                            mobileNumber: selectedNumber,
                            name: selectedCustomer
                              ? selectedCustomer.customerName
                              : customerInfo.name,
                            customerId: selectedCustomer
                              ? selectedCustomer.customerId
                              : customerInfo.customerId,
                          });

                          // Call the API for name suggestions
                          fetchNameSuggestions(selectedNumber);
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "40.5px" }}>Pack Time</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="packTime"
                    value={customerInfo.packTime}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "47.5px" }}>Date Out</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="dateOut"
                    value={customerInfo.dateOut}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "45.9px" }}> Time Out</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="timeOut"
                    value={customerInfo.timeOut}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "37.4px" }}>Shoot Date</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="shootDate"
                    value={customerInfo.shootDate}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "27.5px" }}> Delevered By</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="deliveredBy"
                    value={customerInfo.deliveredBy}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #000",

                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "30.3px" }}>Collected By</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="collectedBy"
                    value={customerInfo.collectedBy}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    pl: 2,
                  }}
                >
                  <Typography
                    style={{ whiteSpace: "nowrap", fontSize: "11px" }}
                  >
                    <b style={{ marginRight: "63.5px" }}> Crew</b>
                    <b>:</b>
                  </Typography>
                  <input
                    name="crew"
                    value={customerInfo.crew}
                    style={{
                      outline: "none",
                      marginLeft: "5px",
                      width: "100%",
                      border: "none",
                      fontSize: "10px",
                    }}
                    onChange={(e) =>
                      setCustomerInfo({
                        ...customerInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Box>
              </Box>
            </Box>
            {/* ... Your existing header code ... */}
            {/* Table */}

            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{
                maxWidth: 1152,
                border: "1px solid #000",
                borderRadius: "0px",
                borderTop: "none",
              }}
            >
              <Table style={{ maxWidth: 2000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      style={{
                        paddingLeft: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingRight: "80px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <span
                            style={{
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              fontSize: "11.5px",
                              paddingLeft: "5px",
                            }}
                          >
                            DC Number:
                          </span>
                          <span style={{ marginLeft: "8px", fontSize: "11px" }}>
                            {dcNumber}
                          </span>
                        </Box>
                        <Box>
                          <span
                            style={{
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              fontSize: "11.5px",
                              paddingLeft: "5px",
                            }}
                          >
                            Client Name:
                          </span>
                          <span
                            style={{
                              marginLeft: "8px",
                              fontSize: "11px",
                              paddingRight: "15px",
                            }}
                          >
                            {customerInfo.name}
                          </span>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      borderBottom: "none",
                      borderTop: "none",
                      pageBreakInside: "avoid", // Avoid breaking rows across pages
                    }}
                  >
                    <TableCell
                      style={{
                        maxWidth: 10,
                        // whiteSpace: "nowrap",
                        border: "none",
                        padding: "5px",
                        fontSize: "11px",
                      }}
                    >
                      <b> Sl. no</b>
                    </TableCell>
                    <TableCell
                      style={{
                        maxWidth: 300,
                        border: "none",
                        padding: "5px",
                        // paddingLeft: "30px",
                        fontSize: "11px",
                        paddingLeft: "15px",
                        borderLeft: "1px solid #00000042",
                        borderRight: "1px solid #00000042",
                      }}
                    >
                      <b>Item Details</b>
                    </TableCell>
                    <TableCell
                      style={{
                        maxWidth: 10,
                        border: "none",
                        padding: "5px",
                        fontSize: "11px",
                        paddingRight: "95px",
                      }}
                    >
                      <b>Qty</b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {consolidatedItemDetails.map(
                    (consolidatedRow, index) =>
                      consolidatedRow.itemStatus !== "deleted" && (
                        <TableRow
                          key={index}
                          style={{
                            pageBreakInside: "avoid",
                          }}
                        >
                          <TableCell
                            style={{
                              border: "none",
                              padding: "5px",
                              fontSize: "10px",
                              maxWidth: 10,
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              border: "none",
                              padding: "5px",
                              paddingLeft: "10px",
                              maxWidth: 500,
                              borderLeft: "1px solid #00000042",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection:
                                  consolidatedRow.type === "Single"
                                    ? "row"
                                    : "column",
                                flexWrap: "nowrap",
                                paddingLeft: "5px",
                              }}
                            >
                              <Box sx={{ fontSize: "11px" }}>
                                {/* <span style={{ marginRight: "8px" }}>•</span> */}
                                <span>{consolidatedRow.itemName}</span>
                              </Box>
                              {consolidatedRow.type === "Single" && (
                                <Box sx={{ ml: 1 }}>-</Box>
                              )}
                              <Box sx={{ fontSize: "10px" }}>
                                <span style={{ marginLeft: "10px" }}>
                                  ({consolidatedRow.serialNumbers.join(", ")})
                                </span>
                              </Box>
                            </div>

                            {consolidatedRow.type === "Single" && (
                              <Box
                                // name="subCategory"
                                // value={consolidatedRow.itemDetails}
                                // onChange={(e) => handleInputChange(e, index)}
                                // className={classes.editableField}
                                sx={{
                                  paddingLeft: "5px",

                                  fontSize: "10px",
                                }}
                              >
                                {consolidatedRow.itemDetails?.join(", ")}
                              </Box>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                              padding: "5px",
                              fontSize: "10px",
                              maxWidth: 10,
                              marginLeft: "5px",
                              borderLeft: "1px solid #00000042",
                            }}
                          >
                            <Box sx={{ mr: 50 }}>
                              {consolidatedRow.quantity}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{ padding: "0px", borderTop: "1px solid #00000042" }}
                  >
                    <span
                      style={{
                        fontWeight: 550,
                        whiteSpace: "nowrap",
                        fontSize: "11px",
                        paddingLeft: "5px",
                        marginRight: "30px",
                      }}
                    >
                      Checked by:
                      <input
                        name="mobileNumber"
                        value={dcCheckedBy}
                        style={{
                          outline: "none",
                          marginLeft: "5px",
                          width: "200px",
                          border: "none",
                          fontSize: "10px",
                          marginTop: "-2px",
                        }}
                        onChange={(e) => setdcCheckedBy(e.target.value)}
                      />
                    </span>
                    <span
                      style={{
                        fontWeight: 550,
                        whiteSpace: "nowrap",
                        fontSize: "11px",
                        paddingLeft: "5px",
                      }}
                    >
                      Remarks:
                      <input
                        name="mobileNumber"
                        value={remarks}
                        style={{
                          outline: "none",
                          marginLeft: "5px",
                          width: "400px",
                          border: "none",
                          fontSize: "10px",
                          marginTop: "-2px",
                        }}
                        onChange={(e) => setremarks(e.target.value)}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Box>
          {/* Header */}
        </Paper>
      </Box>

      <ReactToPrint
        trigger={() => (
          <Button
            sx={{
              borderRadius: "10px",
              background: "#545de4f7",
              color: "black",
              height: { md: "32px", xs: "25px" },
              textTransform: "none",
              whiteSpace: "nowrap",
              minWidth: { md: "120px", xs: "90px" },
              "&:hover": {
                background: "#545de4f7",
                color: "white",
              },
            }}
          >
            PRINT
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle="print"
        documentTitle="neww"
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          mb: 5,
        }}
      >
        {scanControll && (
          <>
            {renderScanner()}
            <Button onClick={() => setTorchOn((prev) => !prev)}>
              {torchOn ? <FlashlightOffIcon /> : <FlashlightOnIcon />}
            </Button>
          </>
        )}
      </Box>
      {!scanControll && (
        <Box>
          <Button
            sx={{
              display: { md: "none", xs: "flex" },
              borderRadius: "10px",
              maxHeight: "29.5px",
              pl: { md: 2, xs: 1 },
              pr: { md: 2, xs: 1 },
              textTransform: "none",
              whiteSpace: "nowrap",
              maxWidth: "120",
              minWidth: { md: "120", xs: "100px" },
              background: "#ff5400d4",
              color: "black",
              "&:hover": {
                background: "#ff5400d4",
                color: "white",
              },
              m: "auto",
              mb: 5,
            }}
            onClick={() => setscanControll(true)}
          >
            Scan
          </Button>
        </Box>
      )}
      {scanVisible&&<Box sx={{display:{md:'flex', xs:'none'}}}>{renderScanner2()}</Box>}
    </Box>

  );
};

export default BillingPage;
