import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import bg from "../../images/login-bg.png";
import { apiList, invokeApi } from "../apis/ApiServices";
import { useCookies } from "react-cookie";
import { config } from "../config/Config";
import { useNavigate } from "react-router-dom";

function Login() {
  const [cookies] = useCookies();
  const [cookie, setCookie] = useCookies([config.cookieName]);
  const [cookieSess, setcookieSess] = useCookies([config.sessionCookie]);
  const navigate = useNavigate();

  const [isFocusedMail, setIsFocusedMail] = useState(false);
  const [isFocusedPassword, setIsFocusedPassword] = useState(false);
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const handleLogin = async () => {
    setloading(true);
    try {
      // Encode mobile number and password
      let encodedMobileNumber = encodeURIComponent(phone);
      let encodedPassword = encodeURIComponent(password);

      let params = {
        mobileNumber: encodedMobileNumber,
        password: encodedPassword,
      };

      // Call the login API using invokeApi function
      let response = await invokeApi(
        config.apiDomains + apiList.login,
        params,
        cookies
      );

      // Check the response status and handle accordingly
      if (response && response.status >= 200 && response.status < 300) {
        setloading(false);
        setCookie(
          config.cookieName,
          JSON.stringify({
            loginUserId: response.data.id,
            token: response.data.idtoken,
            role: response.data.role,
            photo: response.data.profilePicture,
            name: response.data.fullName,
          })
        );
        setcookieSess(
          config.sessionCookie,
          JSON.stringify({
            expiry: response.data.expiry,
          })
        );
        
        navigate("/v-cams");
        // Perform actions for a successful login, e.g., redirect to another page
      } else {
        console.error(
          "Login failed:",
          response.data.message || "An error occurred"
        );
        setloading(false);
        // Handle login failure, show error message to the user
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error(
        "Error during login:",
        error.message || "An error occurred"
      );
      setloading(false);
      // Handle other errors, e.g., network issues
    }
    setloading(false);
  };

  return (
    <Box sx={{ background: " #F0F0F0" }}>
      <Dialog
        open={loading}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: 200,
            minWidth: { md: 80, xs: 80 },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          flexWrap: "nowrap",
          height: "100vh",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          maxWidth: { md: "70%", xs: "95%" },
          m: "auto",
        }}
      >
        <Box
          component="img"
          src={bg}
          sx={{
            width: { md: "35vw", xs: "100%" },
            borderRadius: "15px",
            mt: { md: 0, xs: 3 },
            height: { md: "87vh", xs: "50%" },
          }}
        ></Box>
        <Box
          sx={{
            width: { md: "35vw", xs: "100%" },
            height: { md: "87vh", xs: "50vh" },
            borderRadius: "15px",
            background: "#FFF",
          }}
        >
          <Box
            sx={{
              color: "#EC1E24",
              textAlign: "center",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              mt: { md: 10, xs: 2 },
            }}
          >
            Login Account
          </Box>
          <Box
            sx={{
              color: "#988E8ED1",
              textAlign: "center",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%",
              m: 3,
              pl: 2,
              pr: 2,
            }}
          >
            We endeavor to realize your creative foresight by providing a wide
            range of high-standard latest cameras, lighting equipment, and
            top-quality accessories with unparalleled, committed service.
          </Box>

          {/* MAIL ID */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              ml: "-7px",
              mt: { md: 8 },
            }}
          >
            <Box
              sx={{ width: "7px", height: "45px", background: "#EC1E24" }}
            ></Box>
            <Box sx={{ width: "75%" }}>
              <input
                placeholder="Mobile Number"
                style={{
                  border: `2px solid ${isFocusedMail ? "#EC1E24" : "#D9D9D9"}`,
                  background: "#D9D9D9",
                  width: "100%",
                  height: "40px",
                  fontSize: "20px",
                  color: "rgb(145, 113, 106)",
                  outline: "none",
                  paddingLeft: "10px",
                }}
                onFocus={() => setIsFocusedMail(true)}
                onBlur={() => setIsFocusedMail(false)}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </Box>
          </Box>

          {/*PASSWORD  */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              mt: 4,
              ml: "-7px",
            }}
          >
            <Box
              sx={{ width: "7px", height: "45px", background: "#EC1E24" }}
            ></Box>
            <Box sx={{ width: "75%" }}>
              <input
                type="password"
                placeholder="Password"
                style={{
                  border: `2px solid ${
                    isFocusedPassword ? "#EC1E24" : "#D9D9D9"
                  }`,
                  background: "#D9D9D9",
                  width: "100%",
                  height: "40px",
                  fontSize: "20px",
                  color: "rgb(145, 113, 106)",
                  outline: "none",
                  paddingLeft: "10px",
                }}
                onFocus={() => setIsFocusedPassword(true)}
                onBlur={() => setIsFocusedPassword(false)}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "80%",
              m: "auto",
              pt: 5,
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <label
                style={{
                  color: "#988E8ED1",
                  display: "flex",
                  alignItems: "center", // Center the checkbox vertically
                }}
              >
                <input type="checkbox" style={{ marginRight: "5px" }} />
                Keep me logged in
              </label>
            </Box>
            <Box
              sx={{
                color: "#EC1E24",
                textAlign: "center",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              Already a member?
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                borderRadius: 24,
                background: "#EC1E24",
                width: "80%",
                mt: 5,
                ml: 1.2,
              }}
            >
              <Button
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: 25,
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%" /* 30px */,
                  letterSpacing: 16,
                  minWidth: "100%",
                }}
                onClick={handleLogin}
              >
                LOG IN
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
